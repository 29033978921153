import React from 'react';
import Head from 'next/head';
import { ModuleBase } from '@/components';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const EmbedModule = ({ data }) => {
  const { inlineScriptEmbed, headingSectionScript } = data || {};

  return (
    <ModuleBase data={data} className="relative">
      <Head>
        <meta property="qqq" content="qqq" />
        {typeof window !== 'undefined' && headingSectionScript && headingSectionScript.length > 0 && (
          <script>{headingSectionScript.replace(/<script>/, '').replace(/<\/script>/, '')}</script>
        )}
        <meta property="qqq2" content="qqq2" />
      </Head>
      <FadeUpStarter className="container">
        <FadeUpAnimation
          className="[&_iframe]:!max-w-full [&_iframe]:mx-auto"
          dangerouslySetInnerHTML={{ __html: inlineScriptEmbed }}
        ></FadeUpAnimation>
      </FadeUpStarter>
    </ModuleBase>
  );
};
export default EmbedModule;
