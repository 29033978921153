import { HeadingTag, ModuleBase, PreHeading, Button } from '@/components';
import { isColorDark, themeFromBg } from '@/utils';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';
import { useEffect, useState } from 'react';

const ListRichTextModule = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  const isDArk = () => {
    if (isColorDark(data.backgroundColour)) {
      return 'text-white';
    } else {
      return 'text-primary';
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  const { headingTitle, preHeading, primaryCTA, statElements, statsAlignment } = data || {};
  return (
    <ModuleBase data={data}>
      <FadeUpStarter
        className={`main-wrapper container py-4 md:pt-0 md:pb-2 flex lg:justify-between ${statsAlignment === 'Left' ? 'flex-col lg:flex-row' : 'flex-col-reverse lg:flex-row-reverse'} ${isDArk()}`}
      >
        <div className={`title-wrapper lg:w-[315px] lg:mb-0 ${statsAlignment === 'Left' ? 'mb-12' : 'mb-0'}`}>
          {preHeading && (
            <FadeUpAnimation>
              <PreHeading className="pre-heading font-cera text-[14px] font-[400] -tracking-semiWider leading-4 mb-4">
                {preHeading}
              </PreHeading>
            </FadeUpAnimation>
          )}

          {headingTitle && (
            <FadeUpAnimation>
              <HeadingTag
                data={headingTitle}
                className={`heading-title text-[40px] font-[400] leading-[44px] -tracking-wide font-ivy-ora mb-6
                            lg:text-[56px] lg:leading-[60px] lg:-tracking-normal lg:mb-8
                `}
              />
            </FadeUpAnimation>
          )}

          {primaryCTA && (
            <FadeUpAnimation className="button-wrapper ">
              <Button theme={themeFromBg(isColorDark(data.backgroundColour))} link={primaryCTA} type="primary"></Button>
            </FadeUpAnimation>
          )}
        </div>

        <div className="stats-wrapper lg:w-[776px] flex flex-col justify-center">
          {statElements.map((statItem, statIndex) => (
            <div className="stat-items  mb-8 flex flex-col lg:flex-row lg:gap-x-4 last:mb-0" key={statIndex}>
              <FadeUpAnimation>
                <p
                  className={`mb-2 font-cera text-medium-regular not-italic font-medium leading-6 lg:w-[240px] lg:mb-0`}
                >
                  {statItem.statistics}
                </p>
              </FadeUpAnimation>
              <FadeUpAnimation>
                {statItem.statisticsLabel && loaded && (
                  <p
                    className={`font-cera text-[16px] lg:w-[427px] text-medium-regular not-italic font-light leading-6`}
                    dangerouslySetInnerHTML={{ __html: statItem.statisticsLabel }}
                  ></p>
                )}
              </FadeUpAnimation>
            </div>
          ))}
        </div>
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default ListRichTextModule;
