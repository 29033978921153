module.exports = {
  // content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './modules/**/*.{js,ts,jsx,tsx}'],
  content: {
    files: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './modules/**/*.{js,ts,jsx,tsx}'], // Files Tailwind will scan
    safelist: ['CelticRoyal', 'Rothay'], // Theme classes that are preserved
  },
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem', // 40px
        sm: '1rem', // 16px
        md: '1.5rem', // 24px
        xl: '2rem', // 32px
        xxl: '2.5rem', // 40px
      },
    },
    screens: {
      xs: '375px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1440px',
      xxxl: '1920px',
      ultraWide: '2500px',
      short: { raw: '(max-height: 800px)' },
    },

    extend: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        black: '#00030F',
        secondary: {
          coolsand: '#F7F1EB',
        },
        highlight: {
          sunshineYellow: '#FFBA34',
          coral: '#D86754',
        },
        restaurant: {
          longBarAndGrill: '#905F2D',
          theGate: '#1C302A',
          goldBar: '#827652',
          theShip: '#223952',
          theJetty: '#467BA6',
          theKings: '#1C3F42',
          upperDeck: '#274B5D',
          harbourKitchen: '#1B213C',
          harbar6: '#BC5949',
        },
        error: '#C9413F',
        errorLight: '#ff8280',
      },
      animation: {
        grow: 'grow 0.7s forwards',
        shrink: 'shrink 0.5s forwards',
      },
      keyframes: {
        grow: {
          '0%': { transform: 'scale(0)' },
          '50%': { transform: 'scale(1.2)' },
          '100%': { transform: 'scale(1)' },
        },
        shrink: {
          '0%': { transform: 'scale(1)' },
          '100%': { transform: 'scale(0)' },
        },
      },
      content: {
        externalLink: 'url("../assets/externalLink.svg")',
        phone: 'url("../assets/phone.svg")',
        mail: 'url("../assets/mail.svg")',
      },
      spacing: {
        7.5: '1.875rem',
        26: '6.5rem',
        30: '7.5rem',
        56: '14.5rem',
      },
      letterSpacing: {
        tightest: '0.64px',
        tighter: '0.48px',
        tight: '0.32px',
        normal: '1.68px',
        ultraWide: '3.2px',
        wide: '1.2px',
        wider: '0.72px',
        semiWider: '0.56px',
        headingLight: '4.8px',
        tagsSmall: '0.2px',
        paragraphReg: '0.4px',
      },
      transitionProperty: {
        width: 'width',
        height: 'height',
      },

      transitionDuration: {
        400: '400ms',
        4000: '4000ms',
        2000: '2000ms',
      },

      margin: {
        25: '6.25rem',
      },
      aspectRatio: {
        '4/3': '4 / 3',
        '3/4': '3 / 4',
        '8/5': '8 / 5',
        '7/6': '7 / 6',
        '6/7': '6 / 7',
      },
      fontFamily: {
        cera: ['Cera', 'Helvetica', 'Arial', 'Tahoma', 'Verdana', 'ui-sans-serif', 'system-ui', '-apple-system'],
        'ivy-ora': [
          'ivyora-display',
          'Georgia',
          'Times New Roman',
          'Baskerville',
          'ui-serif',
          'system-ui',
          '-apple-system',
        ],
      },
      lineHeight: {
        headingReg: '44px',
        16: '16px',
        24: '24px',
        32: '32px',
        44: '44px',
        60: '60px',
        72: '72px',
        120: '120px',
      },

      fontSize: {
        56: '3.5rem', // 56px
        80: '5rem', // 80px
        paragraph: ['0.875rem', '1.25rem'], // 14px 20px
        'large-paragraph': ['1rem', '1.5rem'], // 16px 24px
        'small-paragraph': ['0.75rem', '1.125rem'], //12px 18px
        'tiny-paragraph': ['0.75rem', '1rem'],
        btn: ['0.75rem', '1.25rem'],
        'small-caption': ['0.625rem', '1.25rem'],
        'medium-regular': ['1rem', '1.25rem'], // 16px 20px
        'small-regular': ['0.75rem', '1.25rem'], // 12px 20px
        'large-regular': ['1.125rem', '1.5rem'], // 18px 24px
        t4: ['1.5rem', '2rem'], // 24px 32px
        mt4: ['1.125rem', '1.625rem'], // 18px 26px
        /* TODO set values */
        headingReg: ['40px', '44px'],
        'button-default': ['0.75rem', '1.25rem'],

        // new values
        //paragraph:
        'paragraph-s': '12px',
        'paragraph-m': '14px',
        'paragraph-normal': '16px',
        'paragraph-l': '20px',

        //tags:
        'tag-s': '10px',
        'tag-m': '14px',

        // titles:
        'title-s': '24px',
        'title-m': '40px',
        'title-l': '56px',
        'title-xl': '80px',
        'title-xxl': '120px',
      },
    },
  },
  plugins: [
    require('tailwind-scrollbar-hide'),
    require('tailwindcss-themer')({
      defaultTheme: {
        extend: {
          colors: {
            primary: '#081B36',
            secondary: {
              warmsand: '#F9ECE1',
              general: '#487BA4',
            },
          },
        },
      },
      themes: [
        {
          name: 'Rothay',
          className: 'Rothay',
          extend: {
            colors: {
              primary: '#083633',
              secondary: {
                warmsand: '#E9E0D9',
                general: '#A0522B',
              },
            },
          },
        },
        {
          name: 'CelticRoyal',
          className: 'CelticRoyal',
          extend: {
            colors: {
              primary: '#4E5536',
              secondary: {
                warmsand: '#E9E0D9',
                general: '#E6BE8A',
              },
            },
          },
        },
      ],
    }),
  ],
  // purge: {
  //   content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './modules/**/*.{js,ts,jsx,tsx}'],
  //   options: {
  //     safelist: ['CelticRoyal, Rothay'], // Add theme classes to be preserved
  //   },
  // },
};
