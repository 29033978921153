import React, { useState, useEffect } from 'react';
import { HeadingTag, ModuleBase, UmbracoForm } from '@/components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';
import { useRouter } from 'next/router';

const FormModule = ({ data }) => {
  const [formDefinition, setFormDefinition] = useState();
  const isRobot = useSelector(selectIsRobot);
  const router = useRouter();

  useEffect(() => {
    async function fetchData() {
      const isPreview = !!router.query.isPreview;
      const dataRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/forms/api/v1/definitions/${data.formId}`).catch(
        console.error,
      );
      if (dataRes && dataRes.ok) {
        const formData = await dataRes.json();
        setFormDefinition(formData);
      }
    }

    if (!isRobot) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.formId, isRobot]);

  if (isRobot) return;

  return (
    <ModuleBase data={data} className={classNames('color-from-bg font-cera py-12 order-2', formDefinition?.cssClass)}>
      {data.heading && (
        <HeadingTag
          data={{ heading: data.heading, htag: 'p' }}
          className="heading-title text-center color-from-bg container font-ivy-ora text-title-m not-italic font-normal leading-headingReg -tracking-wide mb-6"
        />
      )}

      <UmbracoForm
        className="container lg:px-[304px]"
        successClass="text-center py-10 text-h3"
        formDefinition={formDefinition}
        backgroundColour={data.backgroundColour}
      />
    </ModuleBase>
  );
};

export default FormModule;
