import { useContext, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { MapContext } from './Map';
import { applyReactStyle } from '@/utils';
// https://github.com/visgl/react-map-gl/blob/master/src/components/marker.ts

export function arePointsEqual(a, b) {
  const ax = Array.isArray(a) ? a[0] : a ? a.x : 0;
  const ay = Array.isArray(a) ? a[1] : a ? a.y : 0;
  const bx = Array.isArray(b) ? b[0] : b ? b.x : 0;
  const by = Array.isArray(b) ? b[1] : b ? b.y : 0;
  return ax === bx && ay === by;
}

const Marker = ({ longitude, latitude, onClick, style, children, offset, ...props }) => {
  const mapContext = useContext(MapContext);

  const marker = useMemo(() => {
    const mk = new window.mapboxgl.Marker({ ...props, element: document.createElement('div') })
      .setLngLat([longitude, latitude])
      .addTo(mapContext);

    mk.getElement().addEventListener('click', (e) => {
      onClick?.({
        type: 'click',
        target: mk,
        originalEvent: e,
      });
    });

    return mk;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);

  useEffect(() => {
    applyReactStyle(marker.getElement(), style);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style]);

  if (offset && !arePointsEqual(marker.getOffset(), offset)) {
    marker.setOffset(offset);
  }

  return createPortal(children, marker.getElement());
};

export default Marker;
