import React, { useRef, useState } from 'react';
import { ModuleBase, EntityCard } from '@/components';
import Flicking from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import CarouselPagination from '@/components/general/CarouselPagination';
import { isColorDark, themeFromBg } from '@/utils';

const CardCarouselGenericModule = ({ data }) => {
  const { cardRow, backgroundColour } = data || {};
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setTotalCount(cardRow[0].props.cards.length);
  };

  return (
    <ModuleBase data={data}>
      {cardRow.map((row, rIndex) => (
        <div key={rIndex} className="w-[343px] md:w-[688px] xl:w-[1224px] m-auto ">
          <Flicking
            ref={carousel}
            align="prev"
            useResizeObserver={true}
            horizontal={true}
            circular={true}
            circularFallback="bound"
            onWillChange={willChange}
            onReady={onReady}
            interruptable={false}
            className="pb-[40px] md:pb-[112px]"
          >
            {row.props.cards.map((card, index) => (
              <div key={index} className="flex mx-2 md:mx-3 lg:mx-5">
                <EntityCard
                  data={card}
                  backgroundColour={backgroundColour}
                  className="w-[306px] md:w-[151px] xl:w-[267px]"
                />
              </div>
            ))}
          </Flicking>
          <div className="block md:hidden">
            <CarouselPagination
              current={currentIndex}
              total={totalCount}
              theme={themeFromBg(isColorDark(backgroundColour))}
              className={`
                [&_.currentPage]:text-[24px] [&_.currentPage]:leading-[32px]
                [&_.totalPage]:text-[24px] [&_.totalPage]:leading-[32px]
                pb-10
              `}
            />
          </div>
        </div>
      ))}
    </ModuleBase>
  );
};
export default CardCarouselGenericModule;
