import classNames from 'classnames';
import PropTypes from 'prop-types';

const CarouselPagination = ({ current, total, className, theme, ...props }) => {
  return (
    <div
      className={classNames(className, theme, 'pagination-wrapper flex flex-col justify-center items-center w-fit')}
      {...props}
    >
      <div
        className={`currentPage font-ivy-ora  text-[24px] font-[400] leading-8 -tracking-wider
                    lg:text-[40px] lg:-tracking-wide lg:leading-[44px]
                   ${theme === 'light' ? 'text-white' : 'text-primary'}
      `}
      >
        {`${current + 1}`.padStart(2, '0')}
      </div>
      <div
        className={`totalPage -mt-[8px]  opacity-30 font-ivy-ora text-[24px] font-[400] leading-8 -tracking-wider
                    lg:text-[40px] lg:-tracking-wide lg:leading-[44px]
                    ${theme === 'light' ? 'text-white' : 'text-primary'}
      `}
      >
        {`${total}`.padStart(2, '0')}
      </div>
    </div>
  );
};

CarouselPagination.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default CarouselPagination;
