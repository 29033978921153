import { isColorDark } from '@/utils';
import React, { useEffect, useState } from 'react';
import { useScrollerMotion } from 'scroller-motion';
import { motion } from 'framer-motion';
import BackToTop from '@/assets/backToTop.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components';
import tailwindConfig from '@/tailwind.config';
import { createPortal } from 'react-dom';

const StickyBookingBarSimpleModuleForDesktop = ({ data, bgColor }) => {
  const { scrollY } = useScrollerMotion();
  const [scrollTop, setScrollTop] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [relative, setRelative] = useState(false);

  useEffect(() => {
    setLoaded(true);
    document.querySelector('.page-container-content').classList.add('bg-primary');
    let distanceFromTop = 5000;
    const loaded = () => {
      if (!document.querySelector('.relative-booking-bar')) return;
      const relativeBookingBar = document.querySelector('.relative-booking-bar').offsetTop - window.innerHeight + 72;
      setTimeout(() => {
        distanceFromTop = relativeBookingBar;
        setShowBar(true);
      }, 100);
    };

    scrollY.onChange((value) => {
      const scrollTop = value;
      if (scrollTop > distanceFromTop) {
        setRelative(true);
      } else {
        setRelative(false);
      }
    });
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollTop(scrollTop);
    };

    const resizeObserver = new ResizeObserver(() => {
      loaded();
    });
    resizeObserver.observe(document.documentElement);

    window.addEventListener('load', loaded);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', loaded);
      resizeObserver.disconnect();
    };
  }, [scrollY]);
  return (
    loaded && (
      <>
        <motion.div
          className={`booking-bar h-0 w-full z-50 bottom-0
          transition-all duration-300
          ${relative ? 'opacity-0' : 'opacity-100'}
          ${scrollTop === 0 ? '!opacity-0' : ''}
          ${navigator.userAgent.indexOf('Firefox') > 0 || /.*Version\/(1[0-4]|[5-9])(\.\d+)* Safari.*/.test(navigator.userAgent) ? 'absolute' : 'sticky'}
          `}
          style={{
            y: scrollY,
            top: 'calc(100vh - 71px)',
            transition: 'margin .5s linear, padding .5s linear, top .5s linear, opacity .3s linear',
          }}
        >
          <div
            style={{ transition: 'opacity .5s linear, width .5s linear' }}
            className={`h-[72px] bg-primary w-full'} 
            ${!showBar ? 'opacity-0' : 'opacity-100'}
            `}
          >
            <StickyBookingBarContent data={data} bgColor={bgColor} />
          </div>
        </motion.div>
        {createPortal(
          <motion.div
            className={`relative-booking-bar booking-bar [&_.content]:h-[72px] bg-primary m-auto  transition-width duration-500 'w-full `}
          >
            {/* <StickyBookingBarContent data={data} bgColor={bgColor} /> */}
          </motion.div>,
          document.querySelector('.page-container-content'),
        )}
      </>
    )
  );
};

const StickyBookingBarSimpleModuleForMobile = ({ data, bgColor }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    loaded &&
    createPortal(
      <div
        className={`z-50 w-full sticky bottom-0 overflow-hidden transition-opacity bg-primary duration-500 ${scrollTop > 0 ? 'opacity-100' : 'opacity-0'} `}
      >
        <StickyBookingBarContent data={data} bgColor={bgColor} />
      </div>,
      document.querySelector('.page-container-content'),
    )
  );
};

const StickyBookingBarContent = ({ data, bgColor }) => {
  const { hotelBookingLink, additionalBookingLink, bookingWidget, makeAdditionalBookingLinkPrimary } = data || {};
  const { t } = useTranslation('common');
  const themeColor = isColorDark(bgColor) ? 'light' : 'dark';
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  let hotelBtn = hotelBookingLink;
  let additionalBtn = additionalBookingLink;
  if (makeAdditionalBookingLinkPrimary) {
    hotelBtn = additionalBookingLink;
    additionalBtn = hotelBookingLink;
  }

  const openWidget = () => {
    if (bookingWidget) {
      const regex = /id="([^"]+)"/;
      const match = regex.exec(bookingWidget);
      const id = match ? match[1] : null;
      const element = document.getElementById(id);
      if (element) {
        element.click();
      }
    }
  };

  return (
    <div
      className={`flex items-center gap-0 sm:gap-5 py-2 transition-opacity duration-500 container h-[72px]  w-full ${themeColor === 'dark' ? 'text-primary [&_path]:!fill-primary' : 'text-white'}`}
    >
      <div
        className="flex gap-2 cursor-pointer items-center min-w-[110px] text-sm"
        onClick={() => scrollToTop()}
        aria-label="back to top icon"
        role="presentation"
      >
        <BackToTop
          className={`${/.*Version\/(1[0-4]|[5-9])(\.\d+)* Safari.*/.test(navigator.userAgent) ? 'mr-2' : ''}`}
        />
        {t('general.$backToTop')}
      </div>
      <div className="grow"></div>
      <div className="flex items-center gap-1 xs:gap-4">
        {additionalBtn && (
          <>
            <Button
              className={`whitespace-nowrap ${makeAdditionalBookingLinkPrimary ? 'order-2' : 'order-1'} ${/.*Version\/(1[0-4]|[5-9])(\.\d+)* Safari.*/.test(navigator.userAgent) ? 'mr-2' : ''}`}
              theme={themeColor}
              onClick={() => openWidget()}
              link={bookingWidget ? { ...additionalBtn, href: '#' } : additionalBtn}
              type={'primary'}
            ></Button>
          </>
        )}
        {hotelBtn && (
          <Button
            className={`whitespace-nowrap ${makeAdditionalBookingLinkPrimary ? 'order-1' : 'order-2'} ${/.*Version\/(1[0-4]|[5-9])(\.\d+)* Safari.*/.test(navigator.userAgent) ? 'mr-2' : ''}`}
            theme={themeColor}
            link={{ ...hotelBtn, text: t('hotel.$bookHotel') }}
            type={'primary'}
          ></Button>
        )}

        {bookingWidget && <div className="hidden" dangerouslySetInnerHTML={{ __html: bookingWidget }} />}
      </div>
    </div>
  );
};

const StickyBookingBarSimpleModule = ({ data, bgColor }) => {
  const [clientOnlyData, setClientOnlyData] = useState(null);

  const { bookingWidget } = data || {};

  useEffect(() => {
    document.querySelector('#bookingWidgetScript')?.remove();
    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/g;
    let match;
    let bookingWidgetInnerScript;
    let bookingWidgetScript;

    const loadWidget = () => {
      while ((match = scriptRegex.exec(bookingWidget)) !== null) {
        bookingWidgetInnerScript = document.createElement('script');
        bookingWidgetInnerScript.async = true;
        bookingWidgetInnerScript.defer = true;
        bookingWidgetInnerScript.id = 'bookingWidgetInnerScript';
        if (!document.querySelector('#bookingWidgetInnerScript') && !match[0].includes('src')) {
          const scriptContent = match[1].trim();
          bookingWidgetInnerScript.innerHTML = scriptContent;
          document.body.appendChild(bookingWidgetInnerScript);
        }
      }
    };
    if (bookingWidget) {
      while ((match = scriptRegex.exec(bookingWidget)) !== null) {
        bookingWidgetScript = document.createElement('script');
        bookingWidgetScript.async = true;
        bookingWidgetScript.defer = true;
        bookingWidgetScript.id = 'bookingWidgetScript';
        if (!document.querySelector('#bookingWidgetScript') && match[0].includes('src')) {
          bookingWidgetScript.src = match[0].match(/src="([^"]+)"/)[1];
          bookingWidgetScript.onload = function () {
            loadWidget();
          };
          document.body.appendChild(bookingWidgetScript);
        }
      }
    }

    setClientOnlyData(true);

    return () => {
      if (typeof bookingWidgetInnerScript !== 'undefined' && bookingWidgetInnerScript.parentNode === document.body) {
        document.body.removeChild(bookingWidgetInnerScript);
      }
      if (typeof bookingWidgetScript !== 'undefined' && bookingWidgetScript.parentNode === document.body) {
        document.body.removeChild(bookingWidgetScript);
      }
    };
  }, [bookingWidget]);

  return (
    clientOnlyData &&
    (window.innerWidth > parseInt(tailwindConfig.theme.screens.lg, 10) ? (
      <StickyBookingBarSimpleModuleForDesktop data={data} bgColor={bgColor} />
    ) : (
      <StickyBookingBarSimpleModuleForMobile data={data} bgColor={bgColor} />
    ))
  );
};
export default StickyBookingBarSimpleModule;
