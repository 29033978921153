import PropTypes from 'prop-types';
import { ResponsiveImage, PreHeading } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Button from '../general/Button';

const ArticleCard = ({ data, className, ...props }) => {
  const { image, preHeading, headingTitle, description, primaryCta } = data || {};
  const { t } = useTranslation('common');

  const primaryCtaWithText = {
    ...primaryCta,
    text: t('cards.$readMore'),
  };

  return (
    <div
      {...props}
      className={classNames(
        `main-wrapper bg-white flex flex-col xl:flex-row font-cera w-[306px] h-[612px] xl:w-[916px] xl:h-[425px]`,
        className,
      )}
    >
      <div className="img-wrapper aspect-[3/2] min-h-[306px] w-full xl:w-[459px] relative">
        <ResponsiveImage
          image={image}
          widths={{ xs: 306, md: 306, xl: 459 }}
          heights={{ xs: 306, md: 306, xl: 425 }}
          showImageCredit
        />
      </div>
      <div className="content-wrapper w-[306px] h-[306px] xl:max-w-[457px] xl:h-auto px-4 xl:px-10 py-12 flex flex-col grow gap-4 xl:gap-6 justify-end bg-white">
        <div className="text-wrapper flex flex-col gap-4 xl:gap-6">
          {preHeading && <PreHeading className=" text-sm leading-4">{preHeading}</PreHeading>}
          {headingTitle && (
            <p
              className="font-ivy-ora text-[20px] leading-6 xl:text-[24px] xl:leading-8"
              role="heading"
              aria-level="4"
              aria-label={data.headingTitle}
            >
              {headingTitle}
            </p>
          )}
          {description && (
            <div
              className="text-[14px] leading-6 xl:text-[16px] line-clamp-2"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
        </div>
        {primaryCta && (
          <div className="button-wrapper inline-flex justify-between h-[50px] ">
            <Button
              className="pt-[10px]"
              aria-label={`Read more: ${headingTitle}`}
              theme={'dark'}
              link={primaryCtaWithText}
              type={'tertiary'}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};

ArticleCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleCard;
