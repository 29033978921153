import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';

const HeadingTag = ({ data, children, ...props }) => {
  const { htag, heading } = data || {};
  const isRobot = useSelector(selectIsRobot);
  if (isRobot) {
    return <div dangerouslySetInnerHTML={{ __html: heading }} />;
  }

  let polishedHeading = heading
    ?.replace(/<(p|h[0-6])>/g, '')
    .replace(/<\/(p|h[0-6])>/g, '')
    .replace(/\n/g, '<br>');
  const content = heading ? { dangerouslySetInnerHTML: { __html: polishedHeading } } : children;

  let accessibilityLevel = '7';
  if (htag && parseInt(htag[1]) > 0) {
    accessibilityLevel = htag[1];
  }

  return React.createElement(htag?.toLowerCase() || 'p', {
    role: 'heading',
    'aria-level': accessibilityLevel,
    ...props,
    ...content,
  });
};

export default HeadingTag;
