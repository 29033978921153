import React from 'react';
import BookingBarModule from './BookingBarModule';
import StickyBookingBarSimpleModule from './StickyBookingBarSimpleModule';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';

const StickyBookingBarModule = ({ data }) => {
  const isRobot = useSelector(selectIsRobot);
  if (isRobot) return;
  if (data.displayBookingBar === false) return null;
  if (data.isSimpleBookingBar) {
    return <StickyBookingBarSimpleModule data={data.simpleBooking} bgColor={data.backgroundColor} />;
  } else if (!data.isSimpleBookingBa && data.fullBooking !== null) {
    return <BookingBarModule data={data.fullBooking} />;
  }
};
export default StickyBookingBarModule;
