import React from 'react';
import CardCarouselFocusArticleModule from './CardCarouselFocusArticleModule.jsx';
import CardCarouselFocusFullBleedModule from './CardCarouselFocusFullBleedModule.jsx';

const CardCarouselFocusModule = ({ data }) => {
  const { cardRow } = data || {};

  return (
    (cardRow[0]?.moduleName === 'ArticleCardRow' && <CardCarouselFocusArticleModule data={data} />) ||
    (cardRow[0]?.moduleName === 'FullBleedCardRow' && <CardCarouselFocusFullBleedModule data={data} />)
  );
};
export default CardCarouselFocusModule;
