import { ModuleBase } from '@/components';
import IconListWithRichTextModuleSidyBySide from './IconListWithRichTextModuleSidyBySide';
import IconListWithRichTextModuleStacked from './IconListWithRichTextModuleStacked';

const IconListWithRichTextModule = ({ data }) => {
  const { stackedLayout } = data;

  return (
    <ModuleBase data={data} className=" font-cera text-black">
      {stackedLayout ? (
        <IconListWithRichTextModuleStacked data={data} />
      ) : (
        <IconListWithRichTextModuleSidyBySide data={data} />
      )}
    </ModuleBase>
  );
};

export default IconListWithRichTextModule;
