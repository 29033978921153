import { useRef } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';
import { useLayoutEffect, loadLazyImage } from '@/utils';

const PageContainer = ({ className, ...props }) => {
  const wrapper = useRef();
  const content = useRef();
  const lazyTimer = useRef();
  const isRobot = useSelector(selectIsRobot);

  useLayoutEffect(() => {
    startToLoadLazyImages();
    watchDisableScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startToLoadLazyImages = () => {
    clearTimeout(lazyTimer.current);
    lazyTimer.current = setTimeout(() => {
      loadLazyImagesChunk();
    }, 2000);
  };

  const watchDisableScroll = () => {
    if (document.getElementsByClassName('disable-scroll').length > 0) {
      document.body.classList.add('!overflow-hidden');
    } else {
      document.body.classList.remove('!overflow-hidden');
    }
    setTimeout(() => {
      watchDisableScroll();
    }, 200);
  };

  // load lazy images
  const loadLazyImagesChunk = () => {
    if (!isRobot) {
      const elList = document.querySelectorAll('.lazy-image:not(.lazy-loaded)');
      if (elList && elList.length > 0) {
        let imgEl = null;
        for (let i = 0; i < 5; i++) {
          loadLazyImage(elList[i]);
          if (elList[i] && i === 4) {
            const media =
              elList[i].children && elList[i].children.length > 0 ? [...elList[i].children, elList[i]] : [elList[i]];

            imgEl = media.find((cEl) => cEl.tagName.toLowerCase() === 'img');
          }
        }
        if (imgEl) {
          if (imgEl.complete) {
            loadLazyImagesChunk();
          } else {
            imgEl.addEventListener('load', () => {
              loadLazyImagesChunk();
            });
          }
        } else {
          loadLazyImagesChunk();
        }
      } else {
        startToLoadLazyImages();
      }
    }
  };

  return (
    <div ref={wrapper}>
      <div className={classnames('page-container will-change-transform', className)} {...props} ref={content} />
    </div>
  );
};

export default PageContainer;
