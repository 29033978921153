import { ModuleBase, Media, PreHeading, HeadingTag, Link, ParallaxAnimation } from '@/components';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';
import { useEffect, useState } from 'react';

const BannerSlimModule = ({ data }) => {
  const { headingTitle, description, preHeading, primaryCTA, secondaryCTA } = data || {};
  const [wrapperWidth, setWrapperWidth] = useState(0);

  useEffect(() => {
    const onResize = () => {
      const wrapper = document.getElementById('bannerSlim');
      setWrapperWidth(wrapper.offsetWidth);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  });

  return (
    <ModuleBase data={data} className="font-cera text-center overflow-hidden bg-black">
      <div
        id="bannerSlim"
        className="main-wrapper w-full h-[400px] xxl:h-[calc((var(--wrapperWidth)*5)/18)] xxl:max-h-[750px] relative"
        style={{ '--wrapperWidth': `${wrapperWidth}px` }}
      >
        <ParallaxAnimation offset={100}>
          <div className="img-wrapper">
            <Media
              media={data.backgroundMedia}
              widths={{ xs: 430, md: 1024, lg: 1440, xl: 1900 }}
              heights={{ xs: 400, sm: 600, md: 600, lg: 600, xl: 600, xxl: 600 }}
              image-class="h-full w-full object-cover"
              className=" h-full w-full object-cover"
              cover
            />
          </div>
        </ParallaxAnimation>
        {data.backgroundMedia?.coverImage?.imageCredit && (
          <div className="image-credit">{data.backgroundMedia.coverImage.imageCredit}</div>
        )}

        <div className="absolute top-0 left-0 right-0 h-full w-full  gradient-light z-0]" />

        <FadeUpStarter className="main-data container w-full md:w-[451px] lg:w-[660px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && (
              <FadeUpAnimation>
                <PreHeading className="pre-heading text-secondary-coolsand text-sm font-[500] font-cera leading-4">
                  {preHeading}
                </PreHeading>
              </FadeUpAnimation>
            )}

            {headingTitle && (
              <FadeUpAnimation>
                <HeadingTag
                  data={headingTitle}
                  className="heading-title title font-ivy-ora text-secondary-coolsand my-6 lg:my-8 text-[40px] leading-[44px] lg:text-[56px] font-normal lg:leading-60 -tracking-normal"
                />
              </FadeUpAnimation>
            )}
          </div>

          {(description || primaryCTA || secondaryCTA) && (
            <div className="description-wrapper  text-paragraph ">
              {/* <div
                dangerouslySetInnerHTML={{ __html: description }}
                className="description font-cera text-[16px] leading-6"
              ></div> */}

              {(primaryCTA || secondaryCTA) && (
                <FadeUpAnimation className="button-wrapper w-fit  mx-auto">
                  <div className="group">
                    <Link className="btn secondary light self-end only:mr-0" link={primaryCTA} />
                  </div>
                </FadeUpAnimation>
              )}
            </div>
          )}
        </FadeUpStarter>
      </div>
    </ModuleBase>
  );
};

export default BannerSlimModule;
