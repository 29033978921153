import React, { useRef, useEffect, useState } from 'react';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import { useTranslation } from 'next-i18next';
import { ResponsiveImage, Button, CarouselLinePagination } from '@/components';
import NavigationRight from '@/assets/navigationRight.svg';
import tailwindConfig from '../../tailwind.config.js';
import classNames from 'classnames';
import { stripHtml } from '@/utils';
import { AnimatePresence, motion } from 'framer-motion';

const HotelsList = ({ pois, isDark, theme }) => {
  const [render, setRender] = useState(true);
  const [activeIndex, setActiveIndex] = useState(3);
  const [panelCount, setPanelCount] = useState(0);
  const [flickingOptions, setFlickingOptions] = useState({
    circular: true,
    panelsPerView: 5,
    disableOnInit: false,
    defaultIndex: 3,
  });
  const { t } = useTranslation('common');

  const carousel = useRef();

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  useEffect(() => {
    setRender(false);
    handleResize();
    setTimeout(() => {
      setRender(true);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pois]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    if (window.innerWidth <= parseInt(tailwindConfig.theme.screens.sm, 10)) {
      setFlickingOptions({ ...flickingOptions, panelsPerView: 1.3, defaultIndex: 0 });
      setActiveIndex(0);
    } else if (window.innerWidth <= parseInt(tailwindConfig.theme.screens.md, 10)) {
      setFlickingOptions({ ...flickingOptions, panelsPerView: 3.3, defaultIndex: 1 });
      setActiveIndex(1);
    } else {
      setFlickingOptions({ ...flickingOptions });
      setActiveIndex(3);
    }

    setRender(false);
    setTimeout(() => {
      setRender(true);
    }, 100);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  const onChanged = (e) => {
    setActiveIndex(e.index);
  };

  return (
    <div className="container flex flex-col">
      <div className="flex items-center min-h-[500px]">
        <button
          className="hidden lg:flex w-9 h-10 items-center justify-start"
          onClick={() => move(0)}
          aria-label="Previous"
        >
          <NavigationRight className={classNames('rotate-180', isDark ? 'fill-secondary-coolsand' : 'fill-primary')} />
        </button>

        {render && (
          <AnimatePresence>
            <Flicking
              ref={carousel}
              align="center"
              useResizeObserver={true}
              horizontal={true}
              circularFallback="bound"
              interruptable={false}
              className="w-full"
              onChanged={onChanged}
              onReady={onReady}
              {...flickingOptions}
            >
              {pois.map((poi, index) => (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.7 }}
                  key={index}
                  className={classNames(
                    'card flex flex-col relative w-full mx-3 min-h-[500px] overflow-hidden',
                    isDark ? 'text-secondary-coolsand' : 'text-primary',
                  )}
                >
                  <div
                    className={classNames(
                      'w-full grow transition-all duration-1000 cursor-grab relative',
                      activeIndex === index ? 'max-h-[236px] sm:h-[200px]' : 'max-h-[372px] sm:h-max',
                    )}
                  >
                    {poi.props?.images && poi.props?.images[0] && (
                      <ResponsiveImage
                        image={poi.props.images[0]}
                        widths={{ xs: 465, lg: 465 }}
                        heights={{ xs: 465, lg: 465 }}
                        showImageCredit
                      />
                    )}
                  </div>
                  <div
                    className={classNames(
                      'flex flex-col justify-between pt-4 transition-all duration-1000',
                      activeIndex === index ? 'max-h-[252px]' : 'max-h-[125px] overflow-hidden',
                    )}
                  >
                    <p className="font-cera text-tag-m not-italic font-normal leading-24">{poi.props.location}</p>
                    <p className="font-ivy-ora text-title-s not-italic font-normal leading-32 -tracking-wider mt-4">
                      {poi.props.title}
                    </p>
                    {poi.props.managedByHarbour && (
                      <p className="font-cera text-tag-m not-italic font-normal leading-24">
                        {t('map.$managedbyHarbourHotels')}
                      </p>
                    )}
                    <p className="mt-4 line-clamp-3 font-cera text-paragraph-normal not-italic font-normal leading-24 text-ellipsis">
                      {stripHtml(poi.props.address)}
                    </p>
                    <Button
                      link={{ href: poi.props.ctaLink.href, text: t('general.$viewhotel') }}
                      type="tertiary"
                      theme={theme}
                      className="max-w-max mt-4"
                    />
                  </div>
                </motion.div>
              ))}
            </Flicking>
          </AnimatePresence>
        )}
        <button className="hidden lg:flex w-9 h-10 items-center justify-end" onClick={() => move(1)} aria-label="Next">
          <NavigationRight className={classNames(isDark ? 'fill-secondary-coolsand' : 'fill-primary')} />
        </button>
      </div>
      <div className="flex justify-center">
        <CarouselLinePagination
          current={activeIndex}
          total={panelCount}
          dark={isDark}
          className="mt-14 w-[200px] md:w-[400px]"
        />
      </div>
    </div>
  );
};

export default HotelsList;
