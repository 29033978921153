import React from 'react';
import classNames from 'classnames';

import { useTranslation } from 'next-i18next';

const CarouselLinePagination = ({ dark, current, total, className, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('flex items-center', className)} aria-label={t('general.$goToSlide')} {...props}>
      <div
        className={classNames(
          `${dark ? 'bg-secondary-coolsand/25' : 'bg-primary/25'}`,
          'w-full parent-container h-[2px] rounded-[24px] relative bg-black overflow-hidden',
        )}
      >
        <div
          className={classNames(
            `${dark ? 'bg-secondary-coolsand' : 'bg-primary'}`,
            ' h-full active-child transition-all duration-[0.7s] absolute rounded-[24px]',
          )}
          style={{ left: `${(current * 100) / total}%`, width: `${100 / total}%` }}
        />
      </div>
    </div>
  );
};

export default CarouselLinePagination;
