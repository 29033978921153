/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import { HeadingTag, ModuleBase, ResponsiveImage } from '@/components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import tailwindConfig from '../tailwind.config.js';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import ArticleCard from '@/components/cards/ArticleCard.jsx';
import ArrowForward from '@/assets/arrowForward.svg';
import Button from '@/components/general/Button.jsx';
import CarouselPagination from '@/components/general/CarouselPagination.jsx';

const CardCarouselFocusArticleModule = ({ data }) => {
  const { cardRow, headingTitle, primaryCta } = data || {};
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isCircular, setIsCircular] = useState(true);
  const willChange = (e) => {
    setCurrentIndex(e.index);
  };
  const onReady = () => {
    setTotalCount(cardRow[0].props.cards.length);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsCircular(window.innerWidth >= parseInt(tailwindConfig.theme.screens.xl, 10));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative min-w-full h-[869px] flex flex-col justify-center overflow-hidden">
      <div className="w-[306px] xl:w-[916px] m-auto my-0 flex justify-between items-center">
        {headingTitle && (
          <HeadingTag
            data={{ heading: headingTitle }}
            className="font-ivy-ora text-[40px] leading-[44px] text-white  z-10"
          />
        )}
        <CarouselPagination
          current={currentIndex}
          total={totalCount}
          theme={'light'}
          className={` z-10
          [&_.currentPage]:lg:text-[24px] [&_.currentPage]:lg:leading-[32px]
          [&_.totalPage]:lg:text-[24px] [&_.totalPage]:lg:leading-[32px]
        `}
        />
      </div>
      {cardRow.map((row, rIndex) => (
        <div key={rIndex} className="w-screen max-w-full m-auto relative">
          <Flicking
            ref={carousel}
            align="center"
            useResizeObserver={true}
            horizontal={true}
            circular={isCircular}
            onWillChange={willChange}
            onReady={onReady}
            interruptable={false}
            className="my-[25px] md:my-[35px] xl:my-16"
          >
            {row.props.cards.map((card, index) => (
              <div key={index} className="card flex mx-3 md:mx-5 xl:mx-[70px] xxl:mx-[115px] bg-white">
                <ArticleCard data={card.props} className="articleCard" />
              </div>
            ))}
          </Flicking>
          <div className="hidden pointer-events-none xl:flex xl:gap-[1000px] xxl:gap-[1134px] absolute z-10  top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <button className="scale-[1.3] pointer-events-auto" onClick={move} aria-label="Prevues">
              <ArrowForward role="presentation" aria-label="Next" />
            </button>

            <button className="scale-[1.3] pointer-events-auto" onClick={() => move(1)} aria-label="Next">
              <ArrowForward role="presentation" aria-label="Next" className="rotate-180" />
            </button>
          </div>
        </div>
      ))}

      <div className="w-[306px] xl:w-[916px] m-auto my-0 text-center xl:mt-6 z-10">
        <Button theme={'light'} link={primaryCta} type={'secondary'}></Button>
      </div>
      <div className="absolute  top-0 left-0 w-full h-full bg-slate-600">
        <div className="relative w-full h-full">
          {cardRow[0].props.cards.map((card, index) => (
            <div
              key={index}
              className={`absolute top-0 left-0 w-full h-full transition-opacity blur-2xl duration-[2000ms] ${index !== currentIndex ? 'opacity-0' : ''}`}
              style={{
                WebkitBackfaceVisibility: 'hidden',
                MozBackfaceVisibility: 'hidden',
                WebkitTransform: 'translate3d(0, 0, 0) translateZ(0)',
                MozTransform: 'translate3d(0, 0, 0) translateZ(0)',
              }}
            >
              <ResponsiveImage
                key={index}
                image={card.props.image}
                widths={{ xs: 300, sm: 300, md: 300, lg: 300, xl: 300, xxl: 300 }}
                heights={{ xs: 300, sm: 300, md: 300, lg: 300, xl: 300, xxl: 300 }}
              />
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};
export default CardCarouselFocusArticleModule;
