import { ModuleBase, Media, SectionContent } from '@/components';

const RichTextAndOneMediaModule = ({ data }) => {
  const setDistance = () => {
    return data.mediaOrientation === 'Portrait' || data.mediaOrientation === ''
      ? 'md:gap-[120px] lg:gap-[210px]'
      : 'md:gap-[80px]';
  };

  const setPadding = () => {
    if (data.mediaOrientation === 'Portrait' || data.mediaOrientation === '') {
      if (data.mediaAlignment === 'Left') {
        return 'lg:pr-[247px]';
      } else {
        return 'lg:pl-[174px]';
      }
    } else {
      if (data.mediaAlignment === 'Left') {
        return 'lg:pr-[174px]';
      } else {
        return 'lg:pl-[95px]';
      }
    }
  };

  return (
    <ModuleBase data={data}>
      <div
        className={`container main-data flex  overflow-hidden ${
          data.mediaAlignment === 'Left'
            ? 'flex-col-reverse md:flex-row-reverse md:justify-between md:pl-0 '
            : 'flex-col md:flex-row md:justify-between md:pr-0 lg:pl-[174px]'
        } ${setDistance()} ${setPadding()}`}
      >
        <div className="text-wrapper md:my-auto  md:w-[475px] color-from-bg">
          <SectionContent data={data} descriptionWrapperclassName={'md:w-[322px]'} />
        </div>

        {data.mediaOrientation === 'Portrait' || data.mediaOrientation === '' ? (
          <div
            className={`img-wrapper w-full h-fit  mx-auto md:mx-0 my-auto  md:w-[49%]
              md:max-w-[451px] md:max-w-[502px] relative 
            ${data.mediaAlignment === 'Left' ? 'mb-4 md:mb-auto md:mx-auto md:mx-0' : 'mt-10 md:mt-10 md:mt-auto md:mx-auto md:mx-0'}`}
          >
            {/* <ParallaxAnimation> */}
            <Media
              media={data.media}
              widths={{ xs: 375, sm: 768, md: 768, lg: 502, xl: 502, xxl: 502 }}
              heights={{ xs: 495, sm: 495, md: 1024, lg: 560, xl: 560, xxl: 560 }}
              className=" h-full w-full object-cover"
              showImageCredit
            />
            {/* </ParallaxAnimation> */}
          </div>
        ) : (
          <div
            className={`img-wrapper aspect-[702/527] w-full md:mx-auto  my-auto h-fit  md:w-[688px] md:mx-0 md:w-[702px] relative 
            ${data.mediaAlignment === 'Left' ? 'mb-4 md:ml-0  md:mb-auto' : 'mt-10 md:mt-10 md:mr-0 md:mt-auto '}
            `}
          >
            {/* <ParallaxAnimation> */}
            <Media
              media={data.media}
              widths={{ xs: 343, sm: 327, md: 688, lg: 703, xl: 703, xxl: 703 }}
              heights={{ xs: 273, sm: 652, md: 597, lg: 527, xl: 527, xxl: 527 }}
              className="absolute h-full w-full object-cover"
              showImageCredit
            />
            {/* </ParallaxAnimation> */}
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default RichTextAndOneMediaModule;
