import PropTypes from 'prop-types';
import { ResponsiveImage } from '..';
import Button from '../general/Button';
import classNames from 'classnames';
import { isColorDark, themeFromBg } from '@/utils';

const GenericCardModel = ({ data, backgroundColour, className, ...props }) => {
  const { image, primaryCta } = data || {};

  return (
    <div className={classNames('main-wrapper flex flex-col', className)} {...props}>
      <div className="img-wrapper aspect-[3/2] w-full">
        {image && (
          <ResponsiveImage
            image={image}
            widths={{ xs: 306, md: 151, lg: 267 }}
            heights={{ xs: 306, md: 151, lg: 267 }}
            className="h-full w-full object-cover"
            image-class="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="content-wrapper font-cera flex flex-col justify-between grow">
        {primaryCta && (
          <div className="button-wrapper inline-flex justify-between gap-4 mt-[26px] md:mt-[18px] lg:mt-[26px]">
            <Button theme={themeFromBg(isColorDark(backgroundColour))} link={primaryCta} type={'tertiary'}></Button>
          </div>
        )}
      </div>
    </div>
  );
};

GenericCardModel.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericCardModel;
