import React from 'react';
import classNames from 'classnames';
import { Link } from '@/components';
import { isColorDark } from '@/utils';

const MenuItem = ({ item, className, backgroundColour, ...props }) => {
  const dividerColor = isColorDark(backgroundColour) ? 'bg-secondary-coolsand' : 'bg-primary';
  return (
    <div
      className={classNames(
        `bg-secondary-general/10  hover:bg-secondary-general/50 transition-all duration-1000 py-6 px-4
            flex justify-between
        `,
        className,
      )}
      {...props}
    >
      <div className="menu-item-details flex flex-col justify-between">
        <p
          className="font-ivy-ora text-title-s not-italic font-normal leading-32 -tracking-wider"
          role="heading"
          aria-level="3"
        >
          {item.title}
        </p>

        <div className="time-wrapper flex items-center relative ">
          <p className="font-cera text-tag-m not-italic font-normal leading-24">{item.datePeriod}</p>
          {item.datePeriod && item.timePeriod && (
            <div className={classNames('divider rounded-full mt-1  w-[2px] h-[2px] mx-4', dividerColor)}></div>
          )}
          <p className="font-cera text-tag-m not-italic font-normal leading-24">{item.timePeriod}</p>
        </div>
      </div>

      <div className="menu-link hover:underline text-right tertiary-button-wrapper self-end max-w-[90px] lg:max-w-[unset]">
        <Link link={item.ctalink} className="font-cera text-tag-m not-italic font-medium leading-24" />
      </div>
    </div>
  );
};

export default MenuItem;
