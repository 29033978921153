import { Button, HeadingTag, ModuleBase, PreHeading } from '@/components';
import { isColorDark, themeFromBg } from '@/utils';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const HeadingVerticalModule = ({ data }) => {
  const { headingTitle, description, preTitle, primaryCTA, secondaryCTA, backgroundColour } = data || {};

  return (
    <ModuleBase data={data}>
      <FadeUpStarter className="container text-center py-[119px] lg:py-[73px]">
        <div className="title-wrapper lg:w-[600px] lg:mx-auto" role="contentinfo" aria-label="contentinfo">
          {preTitle && (
            <FadeUpAnimation>
              <PreHeading
                className={`pre-heading  color-from-bg mb-6 text-[14px] font-[400] font-cera leading-6 tracking-[-0.28px]`}
              >
                {preTitle}
              </PreHeading>
            </FadeUpAnimation>
          )}

          {headingTitle && (
            <FadeUpAnimation>
              <HeadingTag
                data={headingTitle}
                className={`heading-title title font-ivy-ora color-from-bg mb-6
                          text-[40px] font-[400] leading-[44px]
                          lg:text-[56px] lg:leading-[60px] lg:-tracking-normal 
                        `}
              />
            </FadeUpAnimation>
          )}
        </div>

        {(description || primaryCTA || secondaryCTA) && (
          <div className="description-wrapper  text-paragraph color-from-bg">
            <FadeUpAnimation
              dangerouslySetInnerHTML={{ __html: description }}
              className="description font-cera text-[16px] leading-6 lg:w-[400px] lg:mx-auto"
            ></FadeUpAnimation>

            {(primaryCTA || secondaryCTA) && (
              <div className="button-wrapper flex justify-center items-end mt-6">
                <FadeUpAnimation
                  className={` ${secondaryCTA ? 'mr-[66px] first-button-bullet-point' : 'mr-0'} ${themeFromBg(isColorDark(backgroundColour))}`}
                >
                  <Button
                    theme={themeFromBg(isColorDark(backgroundColour))}
                    link={primaryCTA}
                    type={'primary'}
                  ></Button>
                </FadeUpAnimation>

                <FadeUpAnimation>
                  <Button
                    theme={themeFromBg(isColorDark(backgroundColour))}
                    link={secondaryCTA}
                    type={'tertiary'}
                  ></Button>
                </FadeUpAnimation>
              </div>
            )}
          </div>
        )}
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default HeadingVerticalModule;
