import React, { useRef, useState, useEffect } from 'react';
import { Button, ResponsiveImage } from '@/components';
import Pin from '@/assets/pin.svg';
import Close from '@/assets/close.svg';
import tailwindConfig from '@/tailwind.config.js';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { stripHtml } from '@/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';
import Map from '@/components/map/Map';
import Marker from '@/components/map/Marker';
import Popup from '@/components/map/Marker';
import { isSafariAgent } from '@/utils';

const Mapbox = ({ pois }) => {
  const [activePoi, setActivePoi] = useState();

  const map = useRef(null);
  const screens = tailwindConfig.theme.screens;
  const { t } = useTranslation('common');
  const isRobot = useSelector(selectIsRobot);

  const setZoomLevel = (element) => {
    const long = element.props.longitude;
    const lat = element.props.latitude;
    const currentZoom = map.current.getZoom();
    const newZoom = currentZoom > 6.5 ? currentZoom : 6.5;
    flyTo(long, lat, newZoom);
    setActivePoi(element);
  };

  const resetZoomLevel = () => {
    // flyTo(-2.226257, 52.41095, 5.5);
    setActivePoi(null);
  };

  const flyTo = (long, lat, zoom) => {
    const options = {
      center: [long, lat],
      duration: 2000,
      essential: true,
    };
    if (zoom) options.zoom = zoom;
    map.current.flyTo(options);
  };

  // const trimmedTitle = (title) => {
  //   return title.includes('Harbour Hotel') ? title : title.replace('Harbour Beach', '');
  // };

  const controllPressed = useRef(false);
  const mouseHoverMapContainer = useRef(false);
  const scrollingTimeout = useRef(null);
  useEffect(() => {
    const safari = isSafariAgent();

    if (safari && window.innerWidth > parseInt(screens.lg)) {
      document.querySelector('.map-container').classList.add('pointer-events-none');
      const handleKeyDown = (event) => {
        if (event.key === 'Control' || event.keyCode === 91 || event.keyCode === 17) {
          controllPressed.current = true;
          document.querySelector('.map-container').classList.remove('pointer-events-none');
        }
      };

      const handleKeyUp = (event) => {
        if (event.key === 'Control' || event.keyCode === 91 || event.keyCode === 17) {
          controllPressed.current = false;
          document.querySelector('.map-container').classList.add('pointer-events-none');
        }
      };

      const scroll = () => {
        if (mouseHoverMapContainer.current) {
          if (!controllPressed.current && document.querySelector('.mapboxgl-scroll-zoom-blocker')) {
            clearTimeout(scrollingTimeout.current);
            document.querySelector('.mapboxgl-scroll-zoom-blocker').style.transition = 'opacity 0';
            document.querySelector('.mapboxgl-scroll-zoom-blocker').style.opacity = 1;
            scrollingTimeout.current = setTimeout(() => {
              document.querySelector('.mapboxgl-scroll-zoom-blocker').style.transition = 'opacity 0.5s';
              document.querySelector('.mapboxgl-scroll-zoom-blocker').style.opacity = 0;
            }, 500);
          }
        }
      };

      const mapContainer = document.querySelector('.MapFullBleedModule');
      const mouseOver = () => {
        mouseHoverMapContainer.current = true;
      };
      const mouseOut = () => {
        mouseHoverMapContainer.current = false;
      };

      mapContainer.addEventListener('mouseover', mouseOver);
      mapContainer.addEventListener('mouseout', mouseOut);

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      window.addEventListener('scroll', scroll);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
        window.removeEventListener('scroll', scroll);
        mapContainer.removeEventListener('mouseover', mouseOver);
        mapContainer.removeEventListener('mouseout', mouseOut);
      };
    }
  }, [screens]);

  if (isRobot) return;

  return (
    <div className={`map-container w-full h-[566px] xxxl:h-[700px] relative `}>
      <Map
        ref={map}
        initialViewState={{
          longitude: -2.226257,
          latitude: 52.41095,
          zoom: 5.5,
        }}
        onClick={resetZoomLevel}
        cooperativeGestures
        mapStyle="mapbox://styles/harbour-hotels/clutkibqc001o01pd6hsi7orl"
      >
        <AnimatePresence>
          {pois.map(
            (poi) =>
              poi && (
                <Marker
                  key={poi.moduleId}
                  longitude={poi.props.longitude}
                  latitude={poi.props.latitude}
                  anchor="bottom"
                  onClick={(e) => {
                    e.originalEvent.stopPropagation();
                    setZoomLevel(poi);
                  }}
                  style={{ zIndex: poi.moduleId === activePoi?.moduleId ? 100 : 0 }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    exit={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}
                    className="Rothay relative w-[59px] h-[59px] flex justify-center items-center z-0 origin-bottom"
                  >
                    <Pin
                      className={classNames(
                        'h-full absolute',
                        poi.moduleId === activePoi?.moduleId ? 'fill-black' : 'fill-black',
                      )}
                    />
                    <div className="w-5 h-5 z-10 -mt-1">
                      <ResponsiveImage
                        dataNotLazy
                        image={poi.props.logo}
                        widths={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 }}
                        heights={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 }}
                      />
                    </div>
                  </motion.div>
                </Marker>
              ),
          )}
        </AnimatePresence>
        {activePoi && (
          <Popup
            key={activePoi.moduleId}
            longitude={Number(activePoi.props.longitude)}
            latitude={Number(activePoi.props.latitude)}
            offset={[
              window.innerWidth + 1 > parseInt(screens.xxl) ? -350 : window.innerWidth > parseInt(screens.lg) ? 0 : 0,
              window.innerWidth + 1 > parseInt(screens.xxl) ? 100 : window.innerWidth > parseInt(screens.lg) ? 140 : 80,
            ]}
            onClose={resetZoomLevel}
            maxWidth={1000}
            closeButton={false}
            className={classNames(
              'bg-white',
              window.innerWidth < parseInt(screens.sm) && 'right-0 bottom-0 top-[unset] !transform-unset m-4',
            )}
          >
            <div className="flex h-[145px] lg:h-[266px] min-w-full sm:min-w-[343px] lg:w-[627px] lg:overflow-hidden">
              <div className="min-w-[120px] h-auto lg:min-w-[270px] lg:aspect-square">
                {activePoi.props.images && activePoi.props.images[0] && (
                  <ResponsiveImage
                    dataNotLazy
                    image={activePoi.props.images[0]}
                    widths={{ xs: 120, lg: 270 }}
                    heights={{ xs: 140, lg: 270 }}
                  />
                )}
              </div>
              <div className="pl-3 pr-1 sm:px-4 lg:px-6 pb-3 sm:pb-4 w-full lg:max-w-[383px] flex flex-col justify-between">
                <div className="pt-5 lg:pt-10 lg:pb-6 flex flex-col gap-4">
                  <p className="hidden lg:block font-cera text-tag-s not-italic font-normal leading-16 -tracking-tagsSmall text-primary">
                    {activePoi.props.location}
                  </p>
                  <div className="flex flex-col">
                    <p className="text-primary hidden lg:block font-ivy-ora text-title-s not-italic font-normal leading-32 -tracking-wider">
                      {activePoi.props.title}
                    </p>
                    <p className="text-primary lg:hidden  font-ivy-ora text-title-s not-italic font-normal leading-32 -tracking-wider">
                      {activePoi.props.title}
                    </p>
                    {activePoi.props.managedByHarbour && (
                      <p className="font-cera text-tag-m not-italic font-normal leading-24 text-primary/75">
                        {t('map.$managedbyHarbourHotels')}
                      </p>
                    )}
                  </div>
                  <p className="hidden lg:line-clamp-2 font-cera text-tag-m not-italic font-normal leading-24 text-primary text-ellipsis">
                    {stripHtml(activePoi.props.address)}
                  </p>
                </div>
                <Button
                  link={{ ...activePoi.props.ctaLink, text: t('general.$viewhotel') }}
                  type="tertiary"
                  theme="dark"
                  className="max-w-max "
                />
              </div>
              <div>
                <Close className="fill-black m-1 sm:m-2 cursor-pointer" onClick={resetZoomLevel} />
              </div>
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default Mapbox;
