import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '..';
import classNames from 'classnames';

const Button = ({ link, theme, type, className, ...props }) => {
  const tertiaryClass = type === 'tertiary' ? 'tertiary-button-wrapper' : ' ';

  return link ? (
    <div className={classNames('group', tertiaryClass, className)} {...props}>
      <Link
        className={classNames('btn self-end', type === 'tertiary' ? 'tertiary-link' : '', theme, type)}
        link={link}
      />
      {type === 'tertiary' && <div className={classNames('underline-animation', theme)}></div>}
    </div>
  ) : null;
};

Button.propTypes = {
  link: PropTypes.object,
  theme: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Button;
