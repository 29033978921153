import { ModuleBase, Media, HeadingTag } from '@/components';
import { useEffect, useState } from 'react';

const HeroRightAlignedModule = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <ModuleBase data={data} className="relative min-h-[404px] overflow-hidden">
      {loaded && data.backgroundMedia && (
        <div className="absolute inset-0 h-full w-full">
          <Media
            media={data.backgroundMedia}
            widths={{ xs: window.innerWidth }}
            heights={{ xs: 404 }}
            cover
            dataNotLazy
          />
        </div>
      )}
      <div className="absolute  z-[2]  h-full w-full " />
      <div className="absolute z-[3] h-full w-full flex justify-end  text-white">
        <div className=" md:max-w-[50%] xxl:max-w-[40%] absolute  h-full flex items-end justify-end mr-4">
          {data.headingTitle && (
            <HeadingTag
              data={data.headingTitle}
              className="font-ivy-ora -mb-3.5 text-right text-balance leading-60 font-normal not-italic text-56  -tracking-normal lg:text-80 lg:leading-72 lg:tracking-[-3.2px]"
            />
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default HeroRightAlignedModule;
