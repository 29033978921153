import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadingTag, PreHeading, ModuleBase, Media, VideoPlayPauseControl, ResponsiveImage } from '@/components';
import classNames from 'classnames';
import Button from '../components/general/Button';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const HeroCenterAlignedModule = ({ data }) => {
  const media = useRef();
  const { t } = useTranslation('common');

  const mediaPosition = 'absolute top-0 left-0 right-0 h-full w-full';

  const hideHeading = data?.headingLogo ? 'hidden' : 'visible';

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  // const openFullscreen = () => {
  //   media?.current?.openFullscreen();
  // };

  // const handlePlay = () => {
  //   if (isPlaying) {
  //     media?.current?.pause();
  //     setisPlaying(false);
  //   } else {
  //     media?.current?.play();
  //     setisPlaying(true);
  //   }
  // };
  const openWidget = () => {
    if (data.embedScript) {
      const regex = /id="([^"]+)"/;
      const match = regex.exec(data.embedScript);
      const id = match ? match[1] : null;
      const element = document.getElementById(id);
      if (element) {
        element.click();
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative h-screen overflow-hidden text-secondary-coolsand ">
      {loaded && data.backgroundMedia && (
        <div className={mediaPosition}>
          <Media
            ref={media}
            media={data.backgroundMedia}
            widths={{ xs: window.innerWidth }}
            heights={{ xs: window.innerHeight }}
            className="h-full w-full object-cover"
            cover
            dataNotLazy
            showImageCredit
          />
        </div>
      )}
      <div className={mediaPosition + ' bg-primary bg-opacity-30'} />
      <FadeUpStarter className=" relative  h-full ">
        <div className="px-4 flex justify-center items-center h-full flex-col max-w-[688px] mx-auto">
          {data.preHeading && (
            <FadeUpAnimation>
              <PreHeading className="font-cera text-sm not-italic font-medium leading-4 mb-6">
                {data.preHeading}
              </PreHeading>
            </FadeUpAnimation>
          )}

          {data.headingTitle && (
            <FadeUpAnimation>
              <HeadingTag
                data={data.headingTitle}
                className={classNames(
                  `font-ivy-ora leading-60 font-normal not-italic text-56 text-center
                  -tracking-normal lg:text-80
                lg:leading-[85px] lg:tracking-[-3.2px] mt-3 mb-9`,
                  hideHeading,
                )}
              />
            </FadeUpAnimation>
          )}

          {data.headingLogo && (
            <FadeUpAnimation className="logo h-full max-h-[207px] w-full max-w-[256px] mt-3 mb-9">
              <ResponsiveImage
                image={data.headingLogo}
                widths={{ xs: 460, md: 460, lg: 460, xl: 460, xxl: 460, xxxl: 460 }}
                imgProps={{ className: 'w-full h-full object-contain' }}
              />
            </FadeUpAnimation>
          )}

          {data.primaryCTA && (
            <FadeUpAnimation>
              <Button theme={'light'} link={data.primaryCTA} type={'secondary'} className={'mt-3'}></Button>
            </FadeUpAnimation>
          )}

          {data.embedScript && (
            <FadeUpAnimation>
              <Button
                theme={'light'}
                onClick={() => openWidget()}
                link={{ text: t('hotel.$bookTable'), href: '#' }}
                type={'secondary'}
                className={'mt-3'}
              ></Button>
            </FadeUpAnimation>
          )}
        </div>
        {data.backgroundMedia?.vimeoId && (
          <FadeUpAnimation>
            <VideoPlayPauseControl media={media} autoPlay={data.backgroundMedia?.autoPlay || false} />
          </FadeUpAnimation>
        )}
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default HeroCenterAlignedModule;
