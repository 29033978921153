import { Button, HeadingTag, ModuleBase, PreHeading } from '@/components';
import { isColorDark, themeFromBg } from '@/utils';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const RichTextModule = ({ data }) => {
  const { headingTitle, preHeading, primaryCTA, secondaryCTA, backgroundColour } = data;

  return (
    <ModuleBase data={data}>
      <div className={`container`}>
        <FadeUpStarter className="rich-text-data md:max-w-[600px] md:mx-auto">
          {preHeading && (
            <FadeUpAnimation>
              <PreHeading
                className={`color-from-bg mb-4 text-[14px] leading-normal -tracking-semiWider
                `}
              >
                {preHeading}
              </PreHeading>
            </FadeUpAnimation>
          )}
          {headingTitle && (
            <FadeUpAnimation>
              <HeadingTag
                data={headingTitle}
                className={`text-${data.headingTitle.htag} color-from-bg mb-8`}
              ></HeadingTag>
            </FadeUpAnimation>
          )}

          <FadeUpAnimation
            className="richtext-content color-from-bg"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />

          {(primaryCTA || secondaryCTA) && (
            <div className="button-wrapper flex justify-start items-end mt-8">
              <FadeUpAnimation
                className={` ${secondaryCTA && primaryCTA ? 'mr-[66px] first-button-bullet-point' : 'mr-0'} ${themeFromBg()}`}
              >
                <Button theme={themeFromBg(isColorDark(backgroundColour))} link={primaryCTA} type={'primary'}></Button>
              </FadeUpAnimation>

              <FadeUpAnimation>
                <Button
                  theme={themeFromBg(isColorDark(backgroundColour))}
                  link={secondaryCTA}
                  type={'tertiary'}
                ></Button>
              </FadeUpAnimation>
            </div>
          )}
        </FadeUpStarter>
      </div>
    </ModuleBase>
  );
};
export default RichTextModule;
