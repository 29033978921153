import React from 'react';
import PropTypes from 'prop-types';
import { HeadingTag, PreHeading } from '../index';
import { isColorDark, themeFromBg } from '@/utils';
import Button from '../general/Button';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const SectionContent = ({
  headingClassName = 'color-from-bg',
  preHeadingClassName = 'color-from-bg',
  descriptionClassName = 'color-from-bg',
  descriptionWrapperclassName = 'color-from-bg',
  data,
  ...props
}) => {
  const { headingTitle, description, preHeading, primaryCTA, secondaryCTA, backgroundColour } = data || {};

  return (
    <FadeUpStarter {...props}>
      <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
        {preHeading && (
          <FadeUpAnimation>
            <PreHeading
              className={`pre-heading  color-from-bg mb-6 md:mb-4 lg:mb-6
                          text-[14px] font-[400] font-cera leading-6
                          ${preHeadingClassName}
              `}
            >
              {preHeading}
            </PreHeading>
          </FadeUpAnimation>
        )}

        {headingTitle && (
          <FadeUpAnimation>
            <HeadingTag
              data={headingTitle}
              className={`heading-title title font-ivy-ora color-from-bg mb-8 md:mb-6 lg:mb-8
                            text-[40px] font-[400] leading-[44px] md:text-[36px] md:leading-[40px]
                            lg:text-[56px] lg:leading-[60px] lg:-tracking-normal
                            ${headingClassName}
              `}
            />
          </FadeUpAnimation>
        )}
      </div>

      {(description || primaryCTA || secondaryCTA) && (
        <div className={`description-wrapper  text-paragraph color-from-bg ${descriptionWrapperclassName}`}>
          <FadeUpAnimation
            dangerouslySetInnerHTML={{ __html: description }}
            className={`description font-cera text-[16px] leading-6 ${descriptionClassName}`}
          />

          {(primaryCTA || secondaryCTA) && (
            <div className="button-wrapper flex justify-start items-end mt-8 md:mt-6 lg:mt-8">
              <FadeUpAnimation
                className={` ${secondaryCTA && primaryCTA ? 'mr-[66px] first-button-bullet-point' : 'mr-0'} ${themeFromBg(isColorDark(backgroundColour))}`}
              >
                <Button
                  theme={themeFromBg(isColorDark(data.backgroundColour))}
                  className={`text-center`}
                  link={primaryCTA}
                  type={'primary'}
                ></Button>
              </FadeUpAnimation>

              <FadeUpAnimation>
                <Button
                  theme={themeFromBg(isColorDark(data.backgroundColour))}
                  className={`text-center`}
                  link={secondaryCTA}
                  type={'tertiary'}
                ></Button>
              </FadeUpAnimation>
            </div>
          )}
        </div>
      )}
    </FadeUpStarter>
  );
};

SectionContent.propTypes = {
  data: PropTypes.any.isRequired,
};

export default SectionContent;
