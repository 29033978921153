import { useState, useRef } from 'react';
import classNames from 'classnames';
import { useLayoutEffect } from '@/utils';
import ArrowForward from '@/assets/arrowForward.svg';

const StyledCursor = ({ className, ...props }) => {
  const [cursorStyle, setCursorStyle] = useState({ transform: 'translate(-100px, -100px)' });
  const [show, setShow] = useState(null);
  const [cursorClass, setCursorClass] = useState(null);
  const [icon, setIcon] = useState(null);

  const styledCursor = useRef();

  useLayoutEffect(() => {
    document.addEventListener('mousemove', setCursorPosition);
    document.addEventListener('scroll', setCursorPosition);
    //document.addEventListener('mousedown', this.onMousedown);
    //document.removeEventListener('mouseup', this.onMouseup);
    return () => {
      window.removeEventListener('mousemove', setCursorPosition);
      document.removeEventListener('scroll', setCursorPosition);
    };
  }, []);

  const setCursorPosition = (event) => {
    let showCustomCursor = false;

    if (event.composedPath) {
      let level = 0;
      event.composedPath().forEach((element) => {
        if (level > 3) return;

        level++;
        if (element?.getAttribute && element.getAttribute('data-styled-cursor')) {
          showCustomCursor = true;

          setCursorClass(element.getAttribute('data-styled-cursor-class'));
          setIcon(element.getAttribute('data-styled-cursor-icon'));
        }
        setCursorStyle({ transform: `translate(${event.clientX - 32}px, ${event.clientY - 32}px)` });
      });

      setShow(showCustomCursor);
    }
  };

  return (
    <div
      id="styledCursor"
      ref={styledCursor}
      className={classNames(
        'cursor-container fixed w-16 h-16 top-0 left-0 pointer-events-none will-change-transform z-[999] flex justify-center items-center transition duration-75',
        className,
        cursorClass,
      )}
      style={cursorStyle}
      {...props}
    >
      <div
        className={classNames(
          'relative w-full h-full scale-0  rounded-full flex justify-center items-center [&_svg]:transition [&_svg]:duration-200',
          show != null && show ? 'animate-grow' : 'animate-shrink',
        )}
      >
        {icon === 'ArrowForward' && <ArrowForward className="stroke-white" />}
      </div>
    </div>
  );
};

export default StyledCursor;
