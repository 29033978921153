/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { HeadingTag, ModuleBase, ResponsiveImage } from '@/components';
import Flicking from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import tailwindConfig from '../tailwind.config.js';
import CarouselPagination from '@/components/general/CarouselPagination.jsx';
import FullBleedCard from '@/components/cards/FullBleedCard.jsx';
import classNames from 'classnames';

const CardCarouselFocusFullBleedModule = ({ data }) => {
  const { cardRow, headingTitle } = data || {};
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  let progressPadding = 0;

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };
  const onReady = () => {
    setTotalCount(cardRow[0].props.cards.length);
    flickingMove();
  };

  const calcStyleValue = (progressMax, progress, valueStart, valueEnd) => {
    const decreasingProgress = progressMax - progress;
    const progressTime = valueEnd - decreasingProgress - (valueEnd - progressMax) / (progressMax / decreasingProgress);
    const value = decreasingProgress * (valueStart / progressMax) + progressTime;
    return Math.max(value, valueEnd);
  };

  const flickingMove = () => {
    const panels = carousel.current.visiblePanels;
    panels.forEach((panel) => {
      const progress = Math.abs(panel.outsetProgress);
      if (progress !== 0 && progressPadding === 0) {
        const windowSize = window.innerWidth;
        if (windowSize < parseInt(tailwindConfig.theme.screens.md, 10)) {
          progressPadding = 0.85; // sm
        } else if (windowSize < parseInt(tailwindConfig.theme.screens.xl, 10)) {
          progressPadding = 0.8; // md
        } else {
          progressPadding = 0.65; // lg
        }
      }
      const target = panel.element;
      let imgOpacity;
      if (progress !== 0) {
        imgOpacity = calcStyleValue(progressPadding, progress, 1, 0.5);
      }
      target.style.opacity = imgOpacity;
    });
  };

  return (
    <ModuleBase
      data={data}
      className="relative h-[600px] xxl:h-[700px] !py-0

     flex flex-col justify-center overflow-hidden"
    >
      <div className="absolute top-0 left-0 z-10 w-full h-full pointer-events-none flex flex-col justify-between items-center py-6 lg:pt-8 lg:pb-10">
        {headingTitle && (
          <HeadingTag data={headingTitle} className="text-[16px] leading-[24px] font-medium text-white" />
        )}
        {cardRow[0].props.cards.length > 1 && (
          <CarouselPagination
            current={currentIndex}
            total={totalCount}
            theme={'light'}
            className={`
          [&_.currentPage]:text-[24px] [&_.currentPage]:leading-[32px]
          [&_.currentPage]:lg:text-[40px] [&_.currentPage]:lg:leading-[44px]
          [&_.totalPage]:text-[24px] [&_.totalPage]:leading-[32px]
          [&_.totalPage]:lg:text-[40px] [&_.totalPage]:lg:leading-[44px]
        `}
          />
        )}
      </div>

      {cardRow.map((row, rIndex) => (
        <div key={rIndex} className="w-screen max-w-full h-full m-auto ">
          <Flicking
            ref={carousel}
            align="center"
            useResizeObserver={true}
            horizontal={true}
            circular={row.props.cards.length > 2 ? true : false}
            disableOnInit={row.props.cards.length === 1 ? true : false}
            circularFallback="bound"
            onMove={flickingMove}
            onWillChange={willChange}
            onReady={onReady}
            interruptable={false}
            className="h-full CardCarouselFocusFullBleedModule"
          >
            {row.props.cards.map((card, index) => (
              <div
                key={index}
                className="card flex relative w-[75%] px-[30px] sm:px-0 sm:w-[60%] xl:w-[50%] h-full overflow-hidden"
              >
                <FullBleedCard
                  data={card.props}
                  moduleHeadingTitle={headingTitle ?? ''}
                  moduleName={card.moduleName}
                  className={`fullbleed--card ${card.moduleName === 'HotelCard' ? 'mx-16 xl:mx-[202px]' : 'mx-0 xl:mx-0'}`}
                />
              </div>
            ))}
          </Flicking>
        </div>
      ))}
      <div className="absolute top-0 left-0 w-full h-full bg-slate-600">
        <div className="relative w-full h-full">
          {cardRow[0].props.cards.map((card, index) => (
            <div
              key={index}
              className={`absolute top-0 left-0 w-full h-full transition-opacity duration-[1000ms] ${index !== currentIndex ? 'opacity-0' : ''}`}
            >
              <ResponsiveImage
                key={index}
                image={card.props.image}
                widths={{ xs: 1024, sm: 1024, md: 1024, lg: 1024, xl: 1280, xxl: 1536 }}
                showImageCredit
              />
              <div
                className={classNames('absolute left-0 top-0 w-full h-full bg-black/30 duration-300 transition-all')}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};
export default CardCarouselFocusFullBleedModule;
