import React from 'react';
import PropTypes from 'prop-types';
import { PreHeading } from '@/components';
import { stripHtml } from '@/utils';
import Button from '../general/Button';

const QuoteSectionContent = ({
  buttonWrapperClassName = 'text-center',
  quoteWrapperClassName = 'xl:flex w-full justify-center',
  authorSourceWrapperClassName = 'text-center',
  showBottomSpacing = false,
  data,

  ...props
}) => {
  const { quote, author, source, ctaLink } = data || {};

  return (
    <div {...props}>
      <div
        className={`author-source-wrapper ${authorSourceWrapperClassName}`}
        aria-label={`source: ${source}`}
        role="presentation"
      >
        {source && (
          <PreHeading
            className={`font-cera text-center mx-auto text-[14px] leading-4 -tracking-[0.28px] opacity-75 color-from-bg mb-[63px]
                      lg:mb-12 !text-white
`}
          >
            {source}
          </PreHeading>
        )}
      </div>

      <div className={`quote-wrapper ${quoteWrapperClassName}`}>
        {quote && (
          <div
            dangerouslySetInnerHTML={{ __html: stripHtml(quote) }}
            className={`font-ivy-ora text-center text-[24px] font-[400] leading-8 -tracking-wider color-from-bg mb-6
                        lg:text-[56px] lg:leading-[60px] lg:-tracking-normal lg:mb-10 !text-white
            `}
            aria-label="Quote element"
            role="presentation"
          ></div>
        )}
      </div>

      <div className={`author-source-wrapper ${authorSourceWrapperClassName}`}>
        {author && (
          <div
            dangerouslySetInnerHTML={{ __html: author }}
            className={`font-cera font-[300] text-[14px] !text-white md:text-base leading-6 color-from-bg ${ctaLink ? 'mb-[63px]' : 'mb-0'}`}
            aria-label={`author: ${author}`}
            role="presentation"
          ></div>
        )}
      </div>

      {showBottomSpacing && <div className="mt-20"></div>}

      {ctaLink && (
        <div className={`button-wrapper ${buttonWrapperClassName}`}>
          {ctaLink && <Button theme="light" link={ctaLink} type={'primary'}></Button>}
        </div>
      )}
    </div>
  );
};

QuoteSectionContent.propTypes = {
  data: PropTypes.any.isRequired,
  buttonWrapperClassName: PropTypes.string,
};

export default QuoteSectionContent;
