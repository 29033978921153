import React, { useState, useEffect, useRef } from 'react';
import { pushValuesToRouteQuery, assignValuesFromRouteQuery, themeFromBg, isColorDark } from '@/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import PaginationArrow from '@/assets/paginationArrow.svg';

const Pagination = ({ pageSize, totalCount, onChange, queryMode, backgroundColour, ...props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [btns, setBtns] = useState([]);
  const totalPages = useRef(1);
  const router = useRouter();
  const { t } = useTranslation('common');

  useEffect(() => {
    const pageObj = { page: 1 };
    assignValuesFromRouteQuery(router, pageObj);
    setCurrentPage(Number(pageObj.page || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    totalPages.current = Math.ceil(totalCount / pageSize);
    const newBtns = [];
    for (let page = 1; page <= totalPages.current; page++) {
      if (totalPages.current > 4) {
        if (
          page === 1 ||
          currentPage === page ||
          currentPage - 1 === page ||
          currentPage + 1 === page ||
          (page === 2 && currentPage === 4) ||
          (page === totalPages.current && currentPage === totalPages.current - 2) ||
          (currentPage < 4 && totalPages.current > 3 && page < 4) ||
          (currentPage === totalPages.current && page === currentPage - 2)
        ) {
          newBtns.push({ label: page, value: page });
        } else if (
          (page === 2 && currentPage > 3) ||
          (page !== 2 && page === totalPages.current && totalPages.current > 3)
        ) {
          newBtns.push({ label: '...', value: page });
        }
      } else {
        newBtns.push({ label: page < 10 ? '0' + page : page, value: page });
      }
    }
    setBtns(newBtns);
  }, [pageSize, totalCount, currentPage]);

  const setPage = (newPage) => {
    if (queryMode) {
      pushValuesToRouteQuery(router, {
        page: newPage > 1 ? newPage : null,
      });
    }
    if (onChange) {
      onChange({
        page: newPage,
      });
    }
  };

  if (totalPages.current <= 1) return;

  return (
    <div className="flex justify-center items-center" {...props}>
      <button
        className={`flex justify-center items-center mx-4 w-[64px] h-[64px] ${themeFromBg(isColorDark(backgroundColour)) === 'light' ? '[&_path]:!fill-white' : '[&_path]:!fill-primary'}`}
        disabled={currentPage <= 1}
        onClick={() => setPage(currentPage - 1)}
      >
        <PaginationArrow className="-rotate-180" role="presentation" aria-label="Previous" />
      </button>
      {btns.map((btn) => (
        <button
          className={classNames(
            `pagination-group 
            ${currentPage === btn.value ? 'selected' : ''}
            `,
          )}
          key={btn.value}
          disabled={btn.label === '...'}
          onClick={() => setPage(btn.value)}
        >
          <div
            className={`pagination-btn ${themeFromBg(isColorDark(backgroundColour)) === 'light' ? 'light' : 'dark'}`}
            databefore={btn.label}
          >
            {btn.label} {currentPage === btn.value}
          </div>
        </button>
      ))}

      <div
        className={`md:hidden text-subheading mx-5 ${themeFromBg(isColorDark(backgroundColour)) === 'light' ? 'text-white' : 'text-primary'}`}
      >
        {t('general.$pagination', { currentPage, totalPages: totalPages.current })}
      </div>

      <button
        className={`flex justify-center items-center mx-4 w-[64px] h-[64px] ${themeFromBg(isColorDark(backgroundColour)) === 'light' ? '[&_path]:!fill-white' : '[&_path]:!fill-primary'}`}
        disabled={currentPage >= totalPages.current}
        onClick={() => setPage(currentPage + 1)}
      >
        <PaginationArrow className="" role="presentation" aria-label="Next" />
      </button>
    </div>
  );
};
export default Pagination;
