import React from 'react';
import SectionContent from '@/components/cards/SectionContent';
import { isColorDark, themeFromBg } from '@/utils';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const IconListWithRichTextModuleSidyBySide = ({ data }) => {
  const { desktopLayoutAlignment, iconListElements, backgroundColour } = data;

  return (
    <div
      className={`container flex items-center justify-between ${desktopLayoutAlignment === 'Left' ? 'flex-col lg:flex-row' : 'flex-col-reverse lg:flex-row-reverse'}`}
    >
      <SectionContent
        data={data}
        className={`items-start w-full lg:w-[426px]
                [&_.pre-heading]:mb-4 [&_.heading-title]:-tracking-wide [&_.heading-title]:mb-6 [&_.description]:font-[300]
                [&_.heading-title]:text-[40px] [&_.heading-title]:leading-[44px]
                ${desktopLayoutAlignment === 'Left' ? 'mb-12' : 'mt-6'}
    `}
      />

      <FadeUpStarter
        delayChildren={1.5}
        className={`w-full lg:w-[65%] h-fit flex flex-col lg:flex-row lg:flex-wrap opacity-75`}
      >
        {iconListElements.map((iconItem, iconIndex) => (
          <FadeUpAnimation
            key={iconIndex}
            className={`icon-item w-full lg:w-1/2 h-fit mb-4 lg:mb-[18px] last:mb-0 flex items-center`}
          >
            <div className={`relative text-with-bullet pl-4 ${themeFromBg(isColorDark(backgroundColour))}`}>
              <p className="color-from-bg text-[16px] leading-normal font-cera font-normal" link={iconItem.link}>
                {iconItem.text}
              </p>
            </div>
          </FadeUpAnimation>
        ))}
      </FadeUpStarter>
    </div>
  );
};

export default IconListWithRichTextModuleSidyBySide;
