import React from 'react';
import { HeadingTag, PreHeading, Button } from '@/components';
import { isColorDark, themeFromBg } from '@/utils';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const IconListWithRichTextModuleStacked = ({ data }) => {
  const { iconListElements, backgroundColour, preHeading, headingTitle, description, primaryCTA, secondaryCTA } = data;

  return (
    <FadeUpStarter className={`container text-center`}>
      <div className="title-wrapper lg:w-[652px] lg:mx-auto" role="contentinfo" aria-label="contentinfo">
        {preHeading && (
          <FadeUpAnimation>
            <PreHeading
              className={`pre-heading  color-from-bg mb-4
                        text-[14px] font-[400] font-cera leading-6 -tracking-wide
            `}
            >
              {preHeading}
            </PreHeading>
          </FadeUpAnimation>
        )}

        {headingTitle && (
          <FadeUpAnimation>
            <HeadingTag
              data={headingTitle}
              className={`heading-title title font-ivy-ora color-from-bg mb-14 lg:mb-10
                          text-[40px] font-[400] leading-[44px]
            `}
            />
          </FadeUpAnimation>
        )}
      </div>

      {description && (
        <FadeUpAnimation
          dangerouslySetInnerHTML={{ __html: description }}
          className="description lg:w-[652px] lg:mx-auto color-from-bg font-cera text-[16px] leading-6 font-[300] mb-10"
        ></FadeUpAnimation>
      )}

      <div className={`w-full lg:w-[720px] lg:mx-auto h-fit flex justify-center flex-wrap opacity-75 gap-2 md:gap-4`}>
        {iconListElements.map((iconItem, iconIndex) => (
          <FadeUpAnimation key={iconIndex} className={`icon-item  last:mb-0 flex items-center`}>
            <div className={`relative text-with-bullet pl-4 ${themeFromBg(isColorDark(backgroundColour))}`}>
              <p className="color-from-bg text-[14px] leading-normal font-cera " link={iconItem.link}>
                {iconItem.text}
              </p>
            </div>
          </FadeUpAnimation>
        ))}
      </div>

      {(primaryCTA || secondaryCTA) && (
        <div className="button-wrapper flex justify-center items-end mt-14">
          <FadeUpAnimation
            className={` ${secondaryCTA ? 'mr-[66px] first-button-bullet-point' : 'mr-0'} ${themeFromBg(isColorDark(backgroundColour))}`}
          >
            <Button theme={themeFromBg(isColorDark(backgroundColour))} link={primaryCTA} type={'primary'}></Button>
          </FadeUpAnimation>

          <FadeUpAnimation>
            <Button theme={themeFromBg(isColorDark(backgroundColour))} link={secondaryCTA} type={'tertiary'}></Button>
          </FadeUpAnimation>
        </div>
      )}
    </FadeUpStarter>
  );
};

export default IconListWithRichTextModuleStacked;
