import React from 'react';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import { convertAbsoluteLinkToRelative } from '@/utils';
import { useTranslation } from 'next-i18next';

const Link = ({ link, children, onClick, ariaLabel, ...props }) => {
  const fixedLink = convertAbsoluteLinkToRelative(link);

  const { t } = useTranslation('common');
  const _onClick = (event) => {
    if (fixedLink?.href?.includes('#')) {
      // event.preventDefault();
      const hash = fixedLink?.href?.split('#')[1];

      if (hash.length > 1) {
        const element = document.getElementById(hash);

        if (element) {
          setTimeout(() => {
            window.scrollTo({
              top: document.getElementById(hash).offsetTop,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    }
    onClick && onClick(event);
  };

  const databefore = { databefore: link?.text };

  return link ? (
    <NextLink
      href={fixedLink?.href || ''}
      target={fixedLink?.target}
      onClick={_onClick}
      aria-label={fixedLink?.target === '_blank' ? t('general.$newWindowAriaLabel', { ariaLabel }) || link?.text : null}
      {...props}
      {...databefore}
    >
      {children || link?.text}
    </NextLink>
  ) : null;
};

Link.propTypes = {
  link: PropTypes.object,
};

export default Link;
