import { motion } from 'framer-motion';
import { transition } from './transition';

const FadeUpAnimation = ({ children, ...props }) => {
  return (
    <motion.div variants={{ inactive: { opacity: 0, y: 20 }, active: { opacity: 1, y: 0, transition } }} {...props}>
      {children}
    </motion.div>
  );
};

export default FadeUpAnimation;
