import { ModuleBase, PreHeading, HeadingTag } from '@/components';
import { isColorDark } from '@/utils';
import Button from '@/components/general/Button';
import classNames from 'classnames';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const HeadingSideModule = ({ data }) => {
  const { title, text, label, primaryCTA, secondaryCTA, backgroundColour, alignment } = data || {};
  const buttonColour = isColorDark(backgroundColour) ? 'light' : 'dark';
  const dividerColor = isColorDark(backgroundColour) ? 'bg-secondary-coolsand' : 'bg-primary';
  const LeftAligned =
    alignment === 'Right' ? 'md:flex-row-reverse pl-16 md:pl-10 text-right md:text-left' : 'md:flex-row pr-16 md:pr-10';

  return (
    <ModuleBase data={data}>
      <FadeUpStarter
        className={classNames(
          'container font-cera flex flex-col gap-y-4  md:justify-between pt-16 md:pt-[73px] md:pb-4',
          LeftAligned,
        )}
      >
        <div
          className="title-wrapper flex flex-col gap-y-4 
            md:max-w-[256px] lg:max-w-[352px] xl:max-w-[436px]"
          role="contentinfo"
          aria-label="contentinfo"
        >
          {label && (
            <FadeUpAnimation>
              <PreHeading className="pre-heading color-from-bg text-sm not-italic font-light leading-4 -tracking-semiWider lg:tracking-[-0.28px]">
                {label}
              </PreHeading>
            </FadeUpAnimation>
          )}

          {title && (
            <FadeUpAnimation>
              <HeadingTag
                data={title}
                className="heading-title font-ivy-ora color-from-bg flex gap-x-4
                    text-2xl not-italic font-normal leading-8 -tracking-wider"
              />
            </FadeUpAnimation>
          )}
        </div>

        {(text || primaryCTA || secondaryCTA) && (
          <div
            className={classNames(
              'description-wrapper w-full text-paragraph color-from-bg md:max-w-[352px] lg:max-w-[431px] ',
              label ? 'md:pt-8' : 'md:pt-0',
            )}
          >
            <FadeUpAnimation
              dangerouslySetInnerHTML={{ __html: text }}
              className="description text-sm not-italic font-normal leading-6 md:text-base"
            />

            {(primaryCTA || secondaryCTA) && (
              <div
                className={`button-wrapper max-w-[358px] flex ${alignment === 'Right' ? 'justify-end md:justify-start' : 'justify-start'} items-end mt-8 gap-x-8`}
              >
                {primaryCTA && (
                  <FadeUpAnimation>
                    <Button type="primary" theme={buttonColour} link={primaryCTA} />
                  </FadeUpAnimation>
                )}
                {secondaryCTA && (
                  <>
                    <FadeUpAnimation
                      className={classNames('divider flex self-center size-[2px] rounded-full', dividerColor)}
                    />

                    <FadeUpAnimation>
                      <Button type="tertiary" theme={buttonColour} link={secondaryCTA} />
                    </FadeUpAnimation>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default HeadingSideModule;
