/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { ModuleBase } from '@/components';
import CardListingGridOfferAndEvent from './CardListingGridOfferAndEvent';
import CardListingGrid from './CardListingGrid';

const CardListingGridModule = ({ data }) => {
  const { filtersAndCards } = data;
  return (
    <ModuleBase data={data} className="py-20">
      {filtersAndCards.types ? <CardListingGridOfferAndEvent data={data} /> : <CardListingGrid data={data} />}
    </ModuleBase>
  );
};
export default CardListingGridModule;
