import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import NavColumn from './NavColumn';
import Close from '@/assets/navClose.svg';
import tailwindConfig from '@/tailwind.config';

const Nav = ({ open, onClose, navItems, mainNavTitle, globalNavItems, allHotels, metadata, addQueryParam }) => {
  const [activeMenu, setActiveMenu] = useState({ l0: {} });
  const { t } = useTranslation('common');

  const close = () => {
    setActiveMenu({ l0: {} });
    onClose();
  };

  const closeOneLevel = async () => {
    if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
      const wait = document.querySelector('.nav-col-1:not(.translate-x-full)') !== null;
      setActiveMenu({ l0: {} });
      if (wait) await new Promise((resolve) => setTimeout(resolve, 300));
      onClose();
    } else {
      setActiveMenu({ l0: {} });
      if (Object.keys(activeMenu.l0).length == 0) {
        onClose();
      }
    }
  };

  const unsetActiveMenu = () => {
    setActiveMenu({ l0: {} });
  };

  return (
    <>
      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-screen backdrop-blur transition-backdrop-filter duration-500',
          open ? 'h-dvh lg:h-screen disable-scroll' : 'h-0',
        )}
      />
      <div
        className={classNames(
          'bg-black fixed top-0 left-0 z-40 w-screen transition-opacity duration-500',
          open ? 'h-dvh lg:h-screen opacity-50' : 'h-0 opacity-0',
        )}
        onClick={close}
      />

      <div
        className={classNames(
          'fixed top-0 left-0 z-40 w-full lg:w-full h-dvh lg:h-screen overflow-y-auto transition-transform duration-700 ease-out dark:bg-gray-800 overflow-x-hidden ',

          { '-translate-x-full': !open },
          activeMenu && activeMenu[`l0`] && Object.keys(activeMenu[`l0`]).some((key) => key.startsWith('me'))
            ? 'active-menu-me [&_.menu-me-0_.link-element:not(.active)]:lg:opacity-30 [&_.menu-me-0_.menu-me-1_.link-element]:lg:opacity-100'
            : '',
          activeMenu && activeMenu[`l0`] && Object.keys(activeMenu[`l0`]).some((key) => key.startsWith('mah'))
            ? 'active-menu-mah [&_.menu-mah-0_.link-element:not(.active)]:lg:opacity-30 [&_.menu-mah-0_.menu-mah-1_.link-element]:lg:opacity-100 [&_.menu-mah-0_.menu-me-1_.link-element]:opacity-100'
            : '',
        )}
      >
        <div className="absolute top-0 right-0 h-full w-2/3" onClick={close}></div>
        <div
          className={`absolute z-20 w-full h-fit lg:h-auto px-10 flex justify-between items-center lg:pointer-events-none
          ${mainNavTitle ? 'pt-6' : 'pt-8'}
        `}
        >
          <div className="lg:w-[26%] h-fit">
            <button
              onClick={closeOneLevel}
              className="z-10 hover:[&_svg]:stroke-primary/50 [&_svg]:stroke-primary !h-auto pointer-events-auto flex justify-center items-center gap-[13px] mt-[1px]"
              aria-label={t('general.$close')}
            >
              <Close className="stroke-primary " role="presentation" />
              <div className="hidden lg:block">
                {activeMenu && Object.keys(activeMenu.l0).length > 0 ? (
                  <span className="text-primary text-tag-m not-italic font-medium leading-24">
                    {t('general.$close')}
                  </span>
                ) : (
                  <span className="text-primary text-tag-m not-italic font-medium leading-24">
                    {t('general.$close')}
                  </span>
                )}
              </div>
              <span className="lg:hidden text-primary text-tag-m not-italic font-medium leading-24">
                {t('general.$close')}
              </span>
            </button>
            {mainNavTitle && (
              <div className="main-title text-primary text-2xl font-ivy-ora pt-6 w-[calc(100%-40px)] xl:min-w-[285px]">
                {mainNavTitle}
              </div>
            )}
          </div>
        </div>
        <NavColumn
          navItems={navItems}
          globalNavItems={globalNavItems}
          allHotels={allHotels}
          mainNavTitle={mainNavTitle}
          activeMenu={activeMenu}
          level={0}
          setActiveMenu={(sub) => setActiveMenu({ l0: sub })}
          unsetActiveMenu={() => unsetActiveMenu()}
          close={close}
          metadata={metadata}
          addQueryParam={addQueryParam}
        />
      </div>
    </>
  );
};

export default Nav;
