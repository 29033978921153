/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import GenericCardSmall from './GenericCardSmall';
import GenericCardLarge from './GenericCardLarge';
import ProductRestaurantCardLarge from './ProductRestaurantCardLarge';
import ProductRestaurantCardSmall from './ProductRestaurantCardSmall';
import ProductRoomCardLarge from './ProductRoomCardLarge';
import ProductRoomCardSmall from './ProductRoomCardSmall';
import ArticleCardSmall from './ArticleCardSmall';
import ArticleCardLarge from './ArticleCardLarge';
import ArticleCard from './ArticleCard';
import classNames from 'classnames';
import GenericCard from './GenericCard';

const EntityCard = ({ data, backgroundColour, large, className, loading, ...props }) => {
  let _props = { ...props };
  let Component = null;
  if (data.moduleName === 'GenericCard') {
    Component = GenericCard;
  } else if (data.moduleName === 'GenericCardModel') {
    // Component = large ? GenericCardLarge : GenericCardSmall;
  } else if (data.moduleName === 'RestaurantCard') {
    // Component = large ? ProductRestaurantCardLarge : ProductRestaurantCardSmall;
  } else if (data.moduleName === 'RoomCard') {
    // Component = large ? ProductRoomCardLarge : ProductRoomCardSmall;
  } else if (data.moduleName === 'ArticleCard') {
    // Component = ArticleCard;
  }

  return Component ? (
    <Component
      data={data.props}
      backgroundColour={backgroundColour}
      className={classNames(className, `${data.moduleName}${large ? 'Large' : 'Small'}`)}
      {..._props}
    />
  ) : (
    data.moduleName + ' card not implemented'
  );
};

EntityCard.propTypes = {
  data: PropTypes.object.isRequired,
  large: PropTypes.bool,
};

export default EntityCard;
