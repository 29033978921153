import React, { useRef, useState } from 'react';
import { ModuleBase, QuoteSectionContent } from '@/components';
import CarouselPagination from '@/components/general/CarouselPagination';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from '@/assets/arrowForward.svg';

const QuoteCarouselModule = ({ data }) => {
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);

    return data.quotes.length > 1 ? carousel.current.enableInput() : carousel.current.disableInput();
  };

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className=" !bg-secondary-general">
      <div className="relative">
        <Flicking
          ref={carousel}
          align="center"
          useResizeObserver={true}
          defaultIndex={0}
          horizontal={true}
          circular={true}
          onWillChange={willChange}
          onReady={onReady}
          className={`${data.quotes.length > 1 ? 'cursor-grab' : ''}`}
        >
          {data.quotes.map((slide, index) => (
            <div
              key={index}
              className={`w-[235px] mx-[7%] md:w-[438px] lg:w-[638px] lg:mx-[178px]  transition-all duration-1000
                          ${currentIndex === index ? 'opacity-100' : 'opacity-50 transition-all duration-1000'}
                        `}
            >
              <QuoteSectionContent data={{ ...slide }} className={`!text-white `} showBottomSpacing={false} />
            </div>
          ))}
        </Flicking>

        <CarouselPagination
          current={currentIndex}
          total={panelCount}
          theme="light"
          className={`pagination mx-auto mt-[54px] lg:mt-[100px] ${data.quotes.length === 1 ? 'hidden' : 'block'}`}
        />

        <div
          className={`hidden z-10  gap-3 justify-between -mt-10 absolute top-[48%] -translate-y-1/2 w-[960px]
          left-1/2 -translate-x-1/2
          ${data.quotes.length === 1 ? 'hidden' : 'lg:flex'}`}
        >
          <button className="p-0 w-10 h-10 sm:w-12 sm:h-12 z-10" onClick={move} aria-label="Prevues">
            <Arrow />
          </button>

          <button className="p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 rotate-180" onClick={() => move(1)} aria-label="Next">
            <Arrow />
          </button>
        </div>
      </div>
    </ModuleBase>
  );
};
export default QuoteCarouselModule;
