import React, { useEffect, useRef } from 'react';
import { ModuleBase, Media, HeadingTag, Button, ResponsiveImage, VideoPlayPauseControl } from '@/components';
import { useAnimate } from 'framer-motion';
import { setHideNavigation, wrapper } from '@/store';
import classNames from 'classnames';

const setAnimationCookie = () => {
  const date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);

  document.cookie = `animation_cookie=was_played;expires=${date.toUTCString()};path=/`;
};

const HomeHeroModule = ({ data, logo, animationCookie, ...rest }) => {
  const media = useRef();
  const { store } = wrapper.useWrappedStore(rest);
  const [scope, animate] = useAnimate();

  const handleAnimate = async () => {
    document.body.style.overflowY = 'scroll';
    document.body.style.position = 'fixed';
    animate([
      [
        '#firstOverlay',
        {
          y: 'var(--overlay-position)',
        },
        { duration: 1.5, ease: [0.39, 0.57, 0.56, 1] },
      ],
      [
        '#logoWrapper',
        {
          y: 'var(--logo-position)',
        },
        { duration: 1.5, ease: [0.39, 0.57, 0.56, 1], at: '<' },
      ],
      ['#firstOverlay', { backgroundColor: 'rgb(8 27 54 / 0)' }],
    ]);
    await animate([
      [
        '#mediaWrapper',
        {
          clipPath: 'polygon(35% 100%, 35% 75%, 65% 75%, 65% 100%)',
        },
        { duration: 1.5, ease: [0.39, 0.57, 0.56, 1] },
      ],
      [
        '#mediaWrapper',
        {
          clipPath: 'polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%)',
        },
        { duration: 2.5, ease: [0.08, 0.82, 0.17, 1], damping: 5 },
      ],
    ]).then(() => {
      store.dispatch(setHideNavigation(false));
    });
    if (data.media.vimeoId) {
      await animate([
        ['#firstOverlay', { opacity: 0 }, { duration: 1.5, delay: 0.2 }],
        ['#gradientOverlay', { opacity: 1 }, { at: '<', duration: 1.5 }],
        ['#preHeading', { opacity: 1 }, { at: '<', duration: 1.5 }],
        ['#cta', { opacity: 1 }, { at: '<', duration: 1.5 }],
        ['#playbackControls', { opacity: 1, pointerEvents: 'auto' }, { at: '<', duration: 1.5 }],
      ]);
    } else {
      await animate([
        ['#firstOverlay', { opacity: 0 }, { duration: 1.5, delay: 0.2 }],
        ['#gradientOverlay', { opacity: 1 }, { at: '<', duration: 1.5 }],
        ['#preHeading', { opacity: 1 }, { at: '<', duration: 1.5 }],
        ['#cta', { opacity: 1 }, { at: '<', duration: 1.5 }],
      ]);
    }

    document.body.style.position = 'static';

    if (!animationCookie) {
      setAnimationCookie();
    }
  };

  useEffect(() => {
    if (!animationCookie && data.enableAnimation) {
      handleAnimate();
    }
  });

  const openFullscreen = () => {
    media?.current?.openFullscreen();
  };

  return (
    <ModuleBase
      data={data}
      className="relative h-svh overflow-hidden bg-primary"
      aria-modal="true"
      role="dialog"
      aria-label="Main Content"
    >
      <div ref={scope}>
        <div
          id="mediaWrapper"
          className="h-svh w-full"
          style={{
            clipPath:
              !animationCookie && data.enableAnimation ? 'polygon(50% 100%, 50% 100%, 50% 100%, 50% 100%)' : 'unset',
          }}
        >
          <Media
            ref={media}
            media={data.media}
            widths={{ xs: 648, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
            heights={{ xs: 820, sm: 820, md: 820, lg: 820, xl: 820, xxl: 820 }}
            className="size-full object-cover"
            cover
            dataNotLazy
          />
        </div>

        {!animationCookie && data.enableAnimation && (
          <div
            id="firstOverlay"
            className="absolute inset-0 size-full bg-primary z-[24] flex justify-center items-center [--overlay-position:calc(76px-100svh)] md:[--overlay-position:calc(104px-100svh)] short:[--overlay-position:calc(76px-100svh)] will-change-transform"
          >
            <div
              id="logoWrapper"
              className="size-auto max-w-[146px] md:max-w-[185px] max-h-[76px] md:max-h-[104px] [--logo-position:calc(50svh-38px)] md:[--logo-position:calc(50svh-52px)] short:[--logo-position:calc(50svh-38px)] will-change-transform"
            >
              <ResponsiveImage id="logo" image={logo} heights={{ xs: 200 }} dataNotLazy />
            </div>
          </div>
        )}

        <div
          id="gradientOverlay"
          className={classNames(
            'absolute size-full inset-0 bg-primary/30 z-[22]',
            animationCookie || !data.enableAnimation ? 'opacity-100' : 'opacity-0',
          )}
        />

        <div
          role="contentinfo"
          aria-label="contentinfo"
          className="container mx-auto absolute inset-0 size-full flex flex-col items-center justify-center z-[23]"
          onClick={openFullscreen}
        >
          {data.label && (
            <p
              id="preHeading"
              className={classNames(
                'font-cera text-sm lg:text-md leading-4 lg:leading-6 text-white font-medium',
                animationCookie || !data.enableAnimation ? 'opacity-100' : 'opacity-0',
              )}
            >
              {data.label}
            </p>
          )}
          <HeadingTag
            data={data.title}
            className="my-6 text-[56px] lg:text-[120px] leading-60 lg:leading-120 font-ivy-ora text-center text-highlight-sunshineYellow"
          />
          {data.ctaLink && (
            <Button
              id="cta"
              link={data.ctaLink}
              type="secondary"
              theme="light"
              className={animationCookie || !data.enableAnimation ? 'opacity-100' : 'opacity-0'}
            />
          )}
          {data.media?.vimeoId && (
            <VideoPlayPauseControl
              id="playbackControls"
              media={media}
              autoPlay={data.media?.autoPlay || false}
              className={
                animationCookie || !data.enableAnimation
                  ? 'opacity-100 pointer-events-auto'
                  : 'opacity-0 pointer-events-none'
              }
            />
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default HomeHeroModule;
