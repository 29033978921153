import React, { useRef, useState, useEffect } from 'react';
import { ModuleBase, ResponsiveImage } from '@/components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import CarouselPagination from '@/components/general/CarouselPagination';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from '@/assets/arrowForward.svg';
import tailwindConfig from '../tailwind.config.js';
import classNames from 'classnames';

const HeroCarouselCentreAlignedModule = ({ data }) => {
  const carousel = useRef();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  // const [itemNumber, setItemNumber] = useState(0);
  const screens = tailwindConfig.theme.screens;
  const [arrowPosition, setarrowPosition] = useState(0);

  // let progressPadding = 0;

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  useEffect(() => {
    const handleResize = () => {
      // return window.innerWidth < parseInt(screens.lg) ? setItemNumber(1.2) : '';
      setarrowPosition(carousel?.current?.currentPanel?.size - 45);
      setTimeout(() => {
        setarrowPosition(carousel?.current?.currentPanel?.size - 45);
      }, 100);
    };

    handleResize();

    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screens.md, screens.lg]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className=" h-[480px]  relative  lg:h-[700px]">
      <Flicking
        ref={carousel}
        align="prev"
        useResizeObserver={true}
        horizontal={true}
        circular={true}
        onWillChange={willChange}
        onReady={onReady}
        size
      >
        {data.mediaSlides.map((slide, index) => (
          <div key={index} className=" lg:w-[90%] h-[480px] lg:h-[700px] md:w-[90%] w-[85%] relative">
            <ResponsiveImage
              image={slide.image}
              widths={{ xs: 430, sm: 768, md: 768, lg: 1920, xl: 1920, xxl: 1920 }}
              heights={{ xs: 480, sm: 480, md: 480, lg: 700, xl: 700, xxl: 700 }}
              className={''}
              id="carouselItem"
              showImageCredit
            />
            <div
              className={classNames(
                'absolute left-0 top-0 w-full h-full bg-black/50 duration-300 transition-all',
                currentIndex !== index ? 'opacity-1' : 'opacity-0',
              )}
            ></div>
          </div>
        ))}
      </Flicking>

      <CarouselPagination
        current={currentIndex}
        total={panelCount}
        theme={'light'}
        className={`pagination mx-auto z-10 absolute right-4 lg:right-6 bottom-2
                    [&_.currentPage]:text-secondary-coolsand [&_.totalPage]:text-secondary-coolsand
                    [&_.currentPage]:lg:text-[80px] [&_.currentPage]:lg:leading-[72px] [&_.currentPage]:lg:-tracking-ultraWide
                    [&_.totalPage]:lg:text-[80px] [&_.totalPage]:lg:leading-[72px] [&_.totalPage]:lg:-tracking-ultraWide
                    lg:bottom-6
        `}
      />

      <div
        className={`hidden buttonAnim
                    lg:absolute  lg:top-1/2 lg:-translate-y-1/2 lg:z-20 lg:w-[88px] 
                    lg:h-[88px] lg:bg-secondary-warmsand/30 lg:rounded-full
                    lg:flex lg:justify-between lg:items-center
                    
      `}
        style={{ left: `${arrowPosition}px` }}
      >
        <button
          className="p-0 w-[44px] h-[44px] sm:w-12 sm:h-12 z-10  flex justify-center items-center"
          onClick={move}
          aria-label="Prevues"
        >
          <Arrow className="" />
        </button>
        <div className="divider rounded-full bg-white w-[2px] h-[2px]"></div>
        <button
          className="p-0 w-[44px] h-[44px] sm:w-12 sm:h-12 z-10 rotate-180  flex justify-center items-center"
          onClick={() => move(1)}
          aria-label="Next"
        >
          <Arrow />
        </button>
      </div>
    </ModuleBase>
  );
};
export default HeroCarouselCentreAlignedModule;
