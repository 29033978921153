import * as React from 'react';
import { useState, useRef, useEffect, forwardRef, useMemo, useImperativeHandle, createContext } from 'react';
import Script from 'next/script';
import Head from 'next/head';

export const MapContext = createContext();

const Map = forwardRef(function Map({ mapStyle, initialViewState, onClick, ...props }, ref) {
  const [mapInstance, setMapInstance] = useState(null);
  const containerRef = useRef();

  useEffect(() => {
    let isMounted = true;
    let mapbox;

    if (!isMounted) {
      return;
    }

    const createMap = () => {
      if (window.mapboxgl) {
        const { longitude, latitude, ...ivProps } = initialViewState;

        window.mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;
        mapbox = new window.mapboxgl.Map({
          container: containerRef.current,
          style: mapStyle,
          center: [longitude, latitude],
          ...ivProps,
          ...props,
        });

        if (onClick) {
          mapbox.on('click', (e) => {
            onClick(e);
          });
        }

        setMapInstance(mapbox);
      } else {
        setTimeout(() => {
          createMap();
        }, 500);
      }
    };

    createMap();

    return () => {
      isMounted = false;
      if (mapbox) {
        mapbox.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => mapInstance, [mapInstance]);

  const style = useMemo(
    () => ({
      position: 'relative',
      width: '100%',
      height: '100%',
      ...props.style,
    }),
    [props.style],
  );

  const CHILD_CONTAINER_STYLE = {
    height: '100%',
  };

  return (
    <div id={props.id} ref={containerRef} style={style}>
      <Head>
        <link href="https://api.mapbox.com/mapbox-gl-js/v3.3.0/mapbox-gl.css" rel="stylesheet" />
      </Head>
      <Script src="https://api.mapbox.com/mapbox-gl-js/v3.3.0/mapbox-gl.js" defer />
      {mapInstance && (
        <MapContext.Provider value={mapInstance}>
          <div mapboxgl-children="" style={CHILD_CONTAINER_STYLE}>
            {props.children}
          </div>
        </MapContext.Provider>
      )}
    </div>
  );
});

export default Map;
