import { ModuleBase, Button } from '@/components';
import React, { useEffect, useState, forwardRef } from 'react';
import Close from '@/assets/close2.svg';
import BannerLogo from '@/assets/bannerLogo.svg';
import BannerLogoMobile from '@/assets/bannerLogo2.svg';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';

const setCookie = (name, value, seconds) => {
  const expires = new Date(Date.now() + seconds * 1000).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
};

const BannerAlertsModule = forwardRef(({ data, className, onBannerClose }, ref) => {
  const { description, primaryCTA, secondaryCTA } = data || {};
  const [isOpen, setIsOpen] = useState(false);

  const notificationBarVariants = {
    inactive: {
      opacity: 0,
      height: 0,
      y: 20,
      transition: {
        opacity: { duration: 0.3 },
        height: { duration: 0.7, delay: 0.3 },
        y: { duration: 0.3 },
      },
    },
    active: {
      opacity: 1,
      height: 'auto',
      y: 0,
      transition: {
        opacity: { duration: 0.3 },
        height: { duration: 0.7, delay: 0 },
        y: { duration: 0.3 },
      },
    },
    exit: {
      opacity: 0,
      height: 0,
      y: 0,
      transition: {
        opacity: { duration: 0.3 },
        height: { duration: 0.7, delay: 0.3 },
        y: { duration: 0.3 },
      },
    },
  };

  const checkingTime = () => {
    const lastClosed = getCookie('bannerClosedTime');
    if (!lastClosed) return true;

    const currentTime = new Date().getTime();
    const timeDifference = currentTime - Number(lastClosed);

    // 3 hours = 3 * 60 * 60 * 1000 ms
    return timeDifference > 3 * 60 * 60 * 1000;
  };

  useEffect(() => {
    setIsOpen(checkingTime());
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    const currentTime = new Date().getTime();
    setCookie('bannerClosedTime', currentTime, 3 * 60 * 60 * 1000); // Expires in 3 hours

    if (onBannerClose) {
      onBannerClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div ref={ref} className={classNames(className)}>
          <ModuleBase className={classNames('bg-secondary-coolsand')} data={data}>
            <motion.div
              variants={notificationBarVariants}
              initial="inactive"
              animate="active"
              exit="exit"
              transition={{ type: 'tween', bounce: 0 }}
              className="banner-main-wrapper"
            >
              <div
                className={`w-full ${isOpen ? 'h-6 md:h-4 transition-all duration-1000' : 'h-0 md:h-0 transition-all duration-700'}`}
              ></div>

              <div className="md:flex md:justify-between container">
                <div className="description-wrapper-desktop md:w-full md:mr-4 hidden md:flex md:items-start">
                  <div className="logo hidden md:flex mr-6">
                    <BannerLogo />
                  </div>

                  <div
                    className="description text-tag-m not-italic font-normal leading-[22px] hidden md:flex text-primary"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>

                <div className="cta-wrapper-desktop cursor-pointer hidden w-full lg:w-1/2 md:flex md:items-start md:justify-end">
                  <div className="cta-buttons mr-6">
                    {(primaryCTA || secondaryCTA) && (
                      <div className="button-wrapper flex justify-start items-end">
                        <Button
                          theme="dark"
                          className={`text-center ${secondaryCTA ? 'mr-3' : 'mr-0'}`}
                          link={primaryCTA}
                          type={'primary'}
                        ></Button>
                        <Button theme="dark" className={`text-center`} link={secondaryCTA} type={'secondary'}></Button>
                      </div>
                    )}
                  </div>

                  <button className="close flex items-center" onClick={handleClose}>
                    <Close className="mr-3" />
                    <span className="text-tag-m not-italic leading-[22px] font-medium text-primary">Close</span>
                  </button>
                </div>

                <div className="description-wrapper-mobile md:hidden">
                  <div className="flex justify-between items-center mb-4">
                    <div className="logo mr-6">
                      <BannerLogoMobile />
                    </div>
                    <Close onClick={handleClose} />
                  </div>
                  <div
                    className="description mb-4 font-cera text-paragraph-s not-italic font-normal leading-16 text-primary"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  {(primaryCTA || secondaryCTA) && (
                    <div className="button-wrapper-mobile md:hidden flex justify-start items-end">
                      <Button
                        theme="dark"
                        className={`text-center ${secondaryCTA ? 'mr-3' : 'mr-0'}`}
                        link={primaryCTA}
                        type={'primary'}
                      ></Button>
                      <Button theme="dark" className={`text-center`} link={secondaryCTA} type={'secondary'}></Button>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`w-full ${isOpen ? 'h-6 md:h-4 transition-all duration-700' : 'h-0 md:h-0 transition-all duration-700'}`}
              ></div>
            </motion.div>
          </ModuleBase>
        </div>
      )}
    </AnimatePresence>
  );
});

BannerAlertsModule.displayName = 'BannerAlertsModule';

export default BannerAlertsModule;
