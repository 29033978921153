import { motion } from 'framer-motion';
import { transition } from './transition';

const FadeUpStarter = ({ children, delayChildren = 0.5, staggerChildren = 0.2, ...props }) => {
  return (
    <motion.div
      variants={{
        inactive: { opacity: 0 },
        active: { opacity: 1, transition: { ...transition, delayChildren, staggerChildren } },
      }}
      initial="inactive"
      whileInView="active"
      viewport={{ once: true }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default FadeUpStarter;
