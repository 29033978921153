import React, { useState, useEffect } from 'react';
import tailwindConfig from '../tailwind.config.js';
import { ScrollerMotion } from 'scroller-motion';

const SmoothScroller = ({ children }) => {
  const screens = tailwindConfig.theme.screens;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setDisabled(window.innerWidth < parseInt(screens.lg));
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [screens.lg]);

  return disabled ? (
    <>{children}</>
  ) : (
    <ScrollerMotion spring={{ stiffness: 170, damping: 30 }}>{children}</ScrollerMotion>
  );
};

export default SmoothScroller;
